import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import 'jspolyfill-array.prototype.find';
import Header from "./components/Header";

import './../src/styles/global.scss'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Main from './components/Main'

const theme = createMuiTheme({
  palette: {
    primary: {
     main: '#001e45',
   },
   secondary: {
     main: '#0496A8',
     variant:'#007989'
   },
   error: {
     main:'#E04964'
   },
  },
  typography: {
    useNextVariants: true,
    fontFamily:'"mr-eaves-xl-modern", "roboto", "helvetica", "sans-serif"!important',
    fontSize:18
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid rgb(224,224,224)',
        },
        '&:after': {
          borderBottom: '2px solid rgb(0,188,212)',
        },
        '&:hover:not($disabled):before': {
          borderBottom: '1px solid rgb(224,224,224)!important',
        },
      },
      inputType:{
        color: '#62647E'
      }
    },
    MuiStepper:{
      root: {
        paddingLeft:'0!important',
      }
    },
    MuiStepLabel: {
      root: {
        fontSize:'15px',
        letterSpacing: 0
      }
    },
    MuiStepIcon: {
      root: {
        marginRight:"10px",
      },
      text: {
        fontSize:'16px',
        fontWeight:900
      },
      completed: {
        color: '#80C58C!important'
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize:'15px!important'
      }
    },
    MuiTouchRipple: {
      root: {
        display:'none'
      }
    },
    MuiFormControl: {
      root: {
        width:'100%',
        marginTop: '17px'
      }
    },
    MuiFormGroup: {
      root: {
        width:'100%'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '18px',
        overflow:'auto!important',
        lineHeight:'normal!important',
        whiteSpace:'normal',
        fontFamily:'"mr-eaves-xl-modern", "roboto", "helvetica", "sans-serif"!important',
        fontWeight:'normal'
      },
      gutters: {
        padding: '8px!important',
        paddingRight: '0!important'
      }
    },
    MuiInputBase: {
      root:{
        width:'100%',
        fontSize: '18px',
        paddingTop:'10px',
      },
      input: {
        paddingTop:'30px'
      }
    },
    MuiOutlinedInput: {
      input: {
        paddingLeft:'20px',
        color:'#62647E'
      }
    },
    MuiTypography: {
      root: {
        fontWeight: '100!important',
        color:'#001e45!important',
        fontFamily:'"mr-eaves-xl-modern", "roboto", "helvetica", "sans-serif"!important',
      }
    },
    MuiPaper: {
      root: {
        padding: '10px',
        marginBottom:'20px',
        marginTop:'20px'
      }
    },
    MuiFormLabel: {
      root: {
        color:'#001e45!important',
        fontSize: '18px',
        fontFamily:'"mr-eaves-xl-modern", "roboto", "helvetica", "sans-serif"!important',
        transform: 'translate(14px, -6px) scale(1)!important',
        fontWeight: 'normal'
      },
    },
    MuiInputLabel: {
      shrink: {
        transform:'translate(0,1.5px) scale(1)!important'
      },
      outlined: {
        transform: 'translate(14px, -6px) scale(1)!important',
        fontWeight:'normal',
        zIndex:'0!important',
        top: '8px',
        left:'6px',
        paddingRight:'20px'
      },
      formControl: {
        top: '-1px',
        left: '18px',
        backgroundColor:'white',
        paddingRight:'12px'
      },
    },
    MuiButton: {
      outlined: {
        paddingTop: '9px',
        fontFamily:'"mr-eaves-xl-modern", "roboto", "helvetica", "sans-serif"!important',
      }
    }
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <HashRouter>
          <div>
            <Header />
            <Main />
          </div>
      </HashRouter>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
)
