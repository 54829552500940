import React from 'react'

const MainContext = React.createContext()
export const MainConsumer = MainContext.Consumer

class MainProvider extends React.Component {
  state = {
    aboutYouData: {},
    businessDetailData: {},
    whatHappenedData: {},
    howDidItHappenData: {},
    suspectsFormData: {},
    hasError:[false, false, false, false, false],
    updateData:(name,value) => this.updateData(name,value),
    updateHasError:(index,value) => this.updateHasError(index,value)
  }

  updateData = (name,value) => {
    this.setState({[name]:value})
  }
  updateHasError = (index,value) => {
    let hasError = this.state.hasError
    hasError[index] = value

    this.setState({hasError})
  }

  render () {
    return (
      // value prop is where we define what values
      // that are accessible to consumer components
       <MainContext.Provider value={this.state}>
        {this.props.children}
      </MainContext.Provider>
    )
  }
}
export default MainProvider
