import React from "react";
import TextFieldmui from "@material-ui/core/TextField";

import "./textField.scss";

const TextField = ({ InputLabelProps = {}, ...props }) => {
  return (
    <div className="textfield-container">
      <TextFieldmui
        variant="outlined"
        maxLength={props.maxLength ? props.maxLength : "250" }
        InputLabelProps={{ ...InputLabelProps, shrink: true }}
        inputProps={{maxLength: (props.maxLength ? props.maxLength :250)}}
        {...props}
      />
    </div>
  );
};
export default TextField;
