export const steps = [
  "About you",
  "Business detail",
  "What happened",
  "How did it happen",
  "Suspect detail",
  "Submit"
];

export const gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" }
];

export const payment = [
  { label: "Electronic funds transfer", value: "electronic" }
];
export const paymentMethod = [
  { label: "Bank account", value: "bankAccount" },
  { label: "Pay ID", value: "payId" }
];

export const companySizeData = [
  { label: "Small (2-19 employees)", value: "small" }
];

export const australianState = [
  { label: "ACT", value: "ACT" },
  { label: "NSW", value: "NSW" },
  { label: "VIC", value: "VIC" },
  { label: "WA", value: "WA" },
  { label: "NT", value: "NT" },
  { label: "SA", value: "SA" }
];

export const emailReportMandatory = [
  "accountName",
  "bsbNumber",
  "financialInstitution",
  "accountNumber",
  "paymentMethodData",
  "amount",
  "amountReference",
  "transactionDescription"
];

export const moneyReportMandatory = [
  "accountName",
  "bsbNumber",
  "financialInstitution",
  "accountNumber",
  "paymentMethodData",
  "amount",
  "amountReference",
  "transactionDescription"
];

export const bsbList = {
  "01": "Australia & New Zealand Banking Group Limited",
  "03": "Westpac Banking Corporation",
  "73": "Westpac Banking Corporation",
  "06": "Commonwealth Bank of Australia",
  "76": "Commonwealth Bank of Australia",
  "40": "Commonwealth Bank of Australia",
  "08": "National Australia Bank Limited",
  "78": "National Australia Bank Limited",
  "09": "Reserve Bank of Australia",
  "10": "BankSA",
  "11": "St.George Bank",
  "33": "St.George Bank",
  "12": "Bank of Queensland Limited",
  "639": "Bank of Queensland Limited",
  "14": "Rabobank Australia Limited",
  "15": "T&C Town & Country Bank",
  "18": "Macquarie Bank Limited",
  "19": "Bank of Melbourne",
  "21": "JP Morgan Chase Bank, National Association",
  "22": "BNP Paribas",
  "25": "BNP Paribas",
  "23": "Bank of America National Association",
  "24": "Citigroup Pty Limited",
  "26": "BT Financial Group",
  "28": "National Mutual Royal Bank",
  "29": "MUFG Bank, Ltd.",
  "30": "Bankwest",
  "31": "Bank Australia",
  "325": "Beyond Bank Australia",
  "34": "HSBC Bank Australia",
  "985": "HSBC Bank Australia",
  "35": "Bank of China",
  "980": "Bank of China",
  "41": "Deutsche Bank Australia",
  "42": "Colonial Trust Bank",
  "52": "Colonial Trust Bank",
  "45": "Oversea-Chinese Banking Corporation Limited",
  "46": "ADV Advance Bank Australia",
  "47": "CBL Challenge Bank",
  "48": "Suncorp-Metway Limited",
  "664": "Suncorp-Metway Limited",
  "510": "Citibank N.A.",
  "512": "Community First Credit Union",
  "514": "RACQ Bank",
  "517": "Volt Bank",
  "527": "Commonwealth Bank of Australia",
  "533": "Bananacoast Community Credit Union",
  "55": "Bank of Melbourne",
  "57": "Australian Settlements",
  "60": "Commonwealth Bank of Australia",
  "610": "Adelaide Bank",
  "611": "Select Credit Union",
  "630": "ABS Building Society",
  "632": "Bank of Us",
  "633": "Bendigo and Adelaide Bank Limited",
  "634": "Uniting Financial Services",
  "637": "Greater Bank",
  "638": "Heritage Bank",
  "640": "Hume Bank",
  "641": "IMB Ltd",
  "647": "IMB Ltd",
  "642": "Australian Military Bank",
  "645": "Auswide Bank",
  "656": "Auswide Bank",
  "646": "Maitland Mutual Building Society Limited",
  "650": "Newcastle Permanent Building Society",
  "653": "PPB Pioneer Permanent Building Society",
  "654": "ECU Australia Ltd",
  "655": "The Rock Building Society",
  "657": "Greater Bank",
  "659": "G&C Mutual Bank Limited",
  "670": "86400 Bank",
  "676": "Gateway Bank Ltd",
  "70": "Indue Ltd",
  "704191": "BankFirst",
  "704230": "BankVic",
  "704865": "Qudos Bank",
  "721": "Holiday Coast Credit Union",
  "723": "Heritage Isle Credit Union",
  "728": "Summerland Credit Union Ltd",
  "80": "Cuscal",
  "812": "Teachers Mutual Bank",
  "813": "The Capricornian",
  "814": "Credit Union Australia",
  "815": "Police Dept Employees Credit Union",
  "817": "Warwick Credit Union Ltd",
  "818": "Bank of Communications Co Ltd",
  "819": "Industrial and Commercial Bank of China Limited",
  "931": "Mega International Commercial Bank Co, Ltd",
  "833": "Defence Bank Limited",
  "882": "Unity Bank Limited",
  "888": "China Construction Bank Corporation",
  "902": "Australia Post",
  "911": "Sumitomo Mitsui Banking Corporation",
  "913": "State Street Bank and Trust Company",
  "915": "FNC Agency - Bank One",
  "917": "Arab Bank Australia Limited",
  "918": "Mizuho Bank Limited",
  "922": "United Overseas Bank Limited",
  "923": "ING Bank",
  "936": "ING Bank",
  "931": "Mega International Commercial Bank Co",
  "932": "Regional Australia Bank",
  "939": "AMP Bank Limited",
  "941": "Delphi Bank",
  "942": "Bank of Sydney Limited",
  "943": "Taiwan Business Bank",
  "944": "Members Equity Bank Pty Limited",
  "946": "UBS AG",
  "951": "INV BOQ Specialist",
  "952": "Royal Bank of Scotland",
  "969": "Tyro Payments Limited",
  "255": "BNP Paribas Securities Services",
  "880": "Heritage Bank Limited",
  "824": "Sutherland Credit Union Ltd in Australia",
  "313": "mecu Limited in Australia",
  "724": "Railways Credit Union Limited",
  "777": "Police & Nurses Limited",
  "722": "Southern Cross Credit Union Limited"
};

export const countries = [
  { label: "I Don't Know", value: "unsure" },
  { label: "Australia", value: "Australia" },
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "Åland Islands", value: "Åland Islands" },
  { label: "Albania", value: "Albania" },
  { label: "Algeria", value: "Algeria" },
  { label: "American Samoa", value: "American Samoa" },
  { label: "Andorra", value: "Andorra" },
  { label: "Angola", value: "Angola" },
  { label: "Anguilla", value: "Anguilla" },
  { label: "Antarctica", value: "Antarctica" },
  { label: "Antigua & Barbuda", value: "Antigua & Barbuda" },
  { label: "Argentina", value: "Argentina" },
  { label: "Armenia", value: "Armenia" },
  { label: "Aruba", value: "Aruba" },
  { label: "Ascension Island", value: "Ascension Island" },
  { label: "Austria", value: "Austria" },
  { label: "Azerbaijan", value: "Azerbaijan" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Barbados", value: "Barbados" },
  { label: "Belarus", value: "Belarus" },
  { label: "Belgium", value: "Belgium" },
  { label: "Belize", value: "Belize" },
  { label: "Benin", value: "Benin" },
  { label: "Bermuda", value: "Bermuda" },
  { label: "Bhutan", value: "Bhutan" },
  { label: "Bolivia", value: "Bolivia" },
  { label: "Bosnia & Herzegovina", value: "Bosnia & Herzegovina" },
  { label: "Botswana", value: "Botswana" },
  { label: "Brazil", value: "Brazil" },
  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory"
  },
  { label: "British Virgin Islands", value: "British Virgin Islands" },
  { label: "Brunei", value: "Brunei" },
  { label: "Bulgaria", value: "Bulgaria" },
  { label: "Burkina Faso", value: "Burkina Faso" },
  { label: "Burundi", value: "Burundi" },
  { label: "Cambodia", value: "Cambodia" },
  { label: "Cameroon", value: "Cameroon" },
  { label: "Canada", value: "Canada" },
  { label: "Canary Islands", value: "Canary Islands" },
  { label: "Cape Verde", value: "Cape Verde" },
  { label: "Caribbean Netherlands", value: "Caribbean Netherlands" },
  { label: "Cayman Islands", value: "Cayman Islands" },
  { label: "Central African Republic", value: "Central African Republic" },
  { label: "Ceuta & Melilla", value: "Ceuta & Melilla" },
  { label: "Chad", value: "Chad" },
  { label: "Chile", value: "Chile" },
  { label: "China", value: "China" },
  { label: "Christmas Island", value: "Christmas Island" },
  { label: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
  { label: "Colombia", value: "Colombia" },
  { label: "Comoros", value: "Comoros" },
  { label: "Congo - Brazzaville", value: "Congo - Brazzaville" },
  { label: "Congo - Kinshasa", value: "Congo - Kinshasa" },
  { label: "Cook Islands", value: "Cook Islands" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Côte d’Ivoire", value: "Côte d’Ivoire" },
  { label: "Croatia", value: "Croatia" },
  { label: "Cuba", value: "Cuba" },
  { label: "Curaçao", value: "Curaçao" },
  { label: "Cyprus", value: "Cyprus" },
  { label: "Czechia", value: "Czechia" },
  { label: "Denmark", value: "Denmark" },
  { label: "Diego Garcia", value: "Diego Garcia" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominican Republic", value: "Dominican Republic" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Egypt", value: "Egypt" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Equatorial Guinea", value: "Equatorial Guinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Estonia", value: "Estonia" },
  { label: "Ethiopia", value: "Ethiopia" },
  { label: "Eurozone", value: "Eurozone" },
  { label: "Falkland Islands", value: "Falkland Islands" },
  { label: "Faroe Islands", value: "Faroe Islands" },
  { label: "Fiji", value: "Fiji" },
  { label: "Finland", value: "Finland" },
  { label: "France", value: "France" },
  { label: "French Guiana", value: "French Guiana" },
  { label: "French Polynesia", value: "French Polynesia" },
  {
    label: "French Southern Territories",
    value: "French Southern Territories"
  },
  { label: "Gabon", value: "Gabon" },
  { label: "Gambia", value: "Gambia" },
  { label: "Georgia", value: "Georgia" },
  { label: "Germany", value: "Germany" },
  { label: "Ghana", value: "Ghana" },
  { label: "Gibraltar", value: "Gibraltar" },
  { label: "Greece", value: "Greece" },
  { label: "Greenland", value: "Greenland" },
  { label: "Grenada", value: "Grenada" },
  { label: "Guadeloupe", value: "Guadeloupe" },
  { label: "Guam", value: "Guam" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Guernsey", value: "Guernsey" },
  { label: "Guinea", value: "Guinea" },
  { label: "Guinea-Bissau", value: "Guinea-Bissau" },
  { label: "Guyana", value: "Guyana" },
  { label: "Haiti", value: "Haiti" },
  { label: "Honduras", value: "Honduras" },
  { label: "Hong Kong SAR China", value: "Hong Kong SAR China" },
  { label: "Hungary", value: "Hungary" },
  { label: "Iceland", value: "Iceland" },
  { label: "India", value: "India" },
  { label: "Indonesia", value: "Indonesia" },
  { label: "Iran", value: "Iran" },
  { label: "Iraq", value: "Iraq" },
  { label: "Ireland", value: "Ireland" },
  { label: "Isle of Man", value: "Isle of Man" },
  { label: "Israel", value: "Israel" },
  { label: "Italy", value: "Italy" },
  { label: "Jamaica", value: "Jamaica" },
  { label: "Japan", value: "Japan" },
  { label: "Jersey", value: "Jersey" },
  { label: "Jordan", value: "Jordan" },
  { label: "Kazakhstan", value: "Kazakhstan" },
  { label: "Kenya", value: "Kenya" },
  { label: "Kiribati", value: "Kiribati" },
  { label: "Kosovo", value: "Kosovo" },
  { label: "Kuwait", value: "Kuwait" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan" },
  { label: "Laos", value: "Laos" },
  { label: "Latvia", value: "Latvia" },
  { label: "Lebanon", value: "Lebanon" },
  { label: "Lesotho", value: "Lesotho" },
  { label: "Liberia", value: "Liberia" },
  { label: "Libya", value: "Libya" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Lithuania", value: "Lithuania" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Macau SAR China", value: "Macau SAR China" },
  { label: "Macedonia", value: "Macedonia" },
  { label: "Madagascar", value: "Madagascar" },
  { label: "Malawi", value: "Malawi" },
  { label: "Malaysia", value: "Malaysia" },
  { label: "Maldives", value: "Maldives" },
  { label: "Mali", value: "Mali" },
  { label: "Malta", value: "Malta" },
  { label: "Marshall Islands", value: "Marshall Islands" },
  { label: "Martinique", value: "Martinique" },
  { label: "Mauritania", value: "Mauritania" },
  { label: "Mauritius", value: "Mauritius" },
  { label: "Mayotte", value: "Mayotte" },
  { label: "Mexico", value: "Mexico" },
  { label: "Micronesia", value: "Micronesia" },
  { label: "Moldova", value: "Moldova" },
  { label: "Monaco", value: "Monaco" },
  { label: "Mongolia", value: "Mongolia" },
  { label: "Montenegro", value: "Montenegro" },
  { label: "Montserrat", value: "Montserrat" },
  { label: "Morocco", value: "Morocco" },
  { label: "Mozambique", value: "Mozambique" },
  { label: "Myanmar (Burma)", value: "Myanmar (Burma)" },
  { label: "Namibia", value: "Namibia" },
  { label: "Nauru", value: "Nauru" },
  { label: "Nepal", value: "Nepal" },
  { label: "Netherlands", value: "Netherlands" },
  { label: "New Caledonia", value: "New Caledonia" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "Nicaragua", value: "Nicaragua" },
  { label: "Niger", value: "Niger" },
  { label: "Nigeria", value: "Nigeria" },
  { label: "Niue", value: "Niue" },
  { label: "Norfolk Island", value: "Norfolk Island" },
  { label: "North Korea", value: "North Korea" },
  { label: "Northern Mariana Islands", value: "Northern Mariana Islands" },
  { label: "Norway", value: "Norway" },
  { label: "Oman", value: "Oman" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Palau", value: "Palau" },
  { label: "Palestinian Territories", value: "Palestinian Territories" },
  { label: "Panama", value: "Panama" },
  { label: "Papua New Guinea", value: "Papua New Guinea" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Peru", value: "Peru" },
  { label: "Philippines", value: "Philippines" },
  { label: "Pitcairn Islands", value: "Pitcairn Islands" },
  { label: "Poland", value: "Poland" },
  { label: "Portugal", value: "Portugal" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Qatar", value: "Qatar" },
  { label: "Réunion", value: "Réunion" },
  { label: "Romania", value: "Romania" },
  { label: "Russia", value: "Russia" },
  { label: "Rwanda", value: "Rwanda" },
  { label: "Samoa", value: "Samoa" },
  { label: "San Marino", value: "San Marino" },
  { label: "São Tomé & Príncipe", value: "São Tomé & Príncipe" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "Senegal", value: "Senegal" },
  { label: "Serbia", value: "Serbia" },
  { label: "Seychelles", value: "Seychelles" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Singapore", value: "Singapore" },
  { label: "Sint Maarten", value: "Sint Maarten" },
  { label: "Slovakia", value: "Slovakia" },
  { label: "Slovenia", value: "Slovenia" },
  { label: "Solomon Islands", value: "Solomon Islands" },
  { label: "Somalia", value: "Somalia" },
  { label: "South Africa", value: "South Africa" },
  {
    label: "South Georgia & South Sandwich Islands",
    value: "South Georgia & South Sandwich Islands"
  },
  { label: "South Korea", value: "South Korea" },
  { label: "South Sudan", value: "South Sudan" },
  { label: "Spain", value: "Spain" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "St. Barthélemy", value: "St. Barthélemy" },
  { label: "St. Helena", value: "St. Helena" },
  { label: "St. Kitts & Nevis", value: "St. Kitts & Nevis" },
  { label: "St. Lucia", value: "St. Lucia" },
  { label: "St. Martin", value: "St. Martin" },
  { label: "St. Pierre & Miquelon", value: "St. Pierre & Miquelon" },
  { label: "St. Vincent & Grenadines", value: "St. Vincent & Grenadines" },
  { label: "Sudan", value: "Sudan" },
  { label: "Suriname", value: "Suriname" },
  { label: "Svalbard & Jan Mayen", value: "Svalbard & Jan Mayen" },
  { label: "Swaziland", value: "Swaziland" },
  { label: "Sweden", value: "Sweden" },
  { label: "Switzerland", value: "Switzerland" },
  { label: "Syria", value: "Syria" },
  { label: "Taiwan", value: "Taiwan" },
  { label: "Tajikistan", value: "Tajikistan" },
  { label: "Tanzania", value: "Tanzania" },
  { label: "Thailand", value: "Thailand" },
  { label: "Timor-Leste", value: "Timor-Leste" },
  { label: "Togo", value: "Togo" },
  { label: "Tokelau", value: "Tokelau" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinidad & Tobago", value: "Trinidad & Tobago" },
  { label: "Tristan da Cunha", value: "Tristan da Cunha" },
  { label: "Tunisia", value: "Tunisia" },
  { label: "Turkey", value: "Turkey" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "Turks & Caicos Islands", value: "Turks & Caicos Islands" },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "U.S. Outlying Islands", value: "U.S. Outlying Islands" },
  { label: "U.S. Virgin Islands", value: "U.S. Virgin Islands" },
  { label: "Uganda", value: "Uganda" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "United Arab Emirates", value: "United Arab Emirates" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "United Nations", value: "United Nations" },
  { label: "United States", value: "United States" },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Uzbekistan", value: "Uzbekistan" },
  { label: "Vanuatu", value: "Vanuatu" },
  { label: "Vatican City", value: "Vatican City" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Vietnam", value: "Vietnam" },
  { label: "Wallis & Futuna", value: "Wallis & Futuna" },
  { label: "Western Sahara", value: "Western Sahara" },
  { label: "Yemen", value: "Yemen" },
  { label: "Zambia", value: "Zambia" },
  { label: "Zimbabwe", value: "Zimbabwe" }
];
