import React from "react";
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Select from "@material-ui/core/Select";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import AutoComplete from "./../../components/AutoComplete";
import Address from "./../../components/Address";
import Back from "./../../components/BackButton";
import SuspectReport from "./../../components/SuspectReport";

import { MainConsumer } from "./../../providers/MainProvider";

import { removeWhiteSpace, isBlank } from "./../../services/helper";
import { steps } from "../../services/api/static";

import "./suspectDetail.scss";

class SuspectDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeStep: 0,
      isMobile: false,
      orientation: "horizontal",
      showSuspect: false,
      suspectDetailData: [],
      curRecord: {}
    };
    this.suspectReportRef = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resize);
    this.resize();
    this.indexCurrentPage();
    if (!isBlank(this.props.suspectsFormData)) {
      this.setState({
        suspectDetailData:this.props.suspectsFormData
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.suspectsFormData !== state.suspectDetailData) {
      return {
        suspectsFormData: props.suspectsFormData
      };
    }
    return null;
  }

  indexCurrentPage = () => {
    let curPage = this.props.location.pathname.slice(1);
    let activeStep;

    steps.map((step, i) => {
      if (curPage === removeWhiteSpace(step.toLowerCase())) {
        activeStep = i;
      }
    });

    this.setState({
      activeStep
    });
  };

  resize = () => {
    let isMobile = window.innerWidth <= 992;
    let orientation = isMobile ? "vertical" : "horizontal";
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile, orientation });
    }
  };

  handleChange = stateValue => e => {
    this.setState({
      [stateValue]: e.target.value
    });
  };

  openSuspect = () => {
    let showSuspect = true;
    this.setState({ showSuspect });
  };

  handleClose = () => {
    let showSuspect = false;
    let curRecord = [];

    this.setState({ showSuspect, curRecord });
  };

  handleSave = () => {
    let suspectDetailData = this.state.suspectDetailData;

    let newSuspectDetailObj = this.suspectReportRef.current.getData();

    if (newSuspectDetailObj.id) {
      suspectDetailData = suspectDetailData.map(suspect =>
        suspect.id === newSuspectDetailObj.id
          ? (suspect = newSuspectDetailObj)
          : suspect
      );
    } else {
      let id = suspectDetailData.length + 1;
      newSuspectDetailObj.id = id;
      suspectDetailData.push(newSuspectDetailObj);
    }

    this.setState({ suspectDetailData });

    this.handleClose();
  };

  handleDelete = () => {
    let suspectDetailData = this.state.suspectDetailData;
    let curRecord = this.state.curRecord;
    suspectDetailData = suspectDetailData.filter(
      suspectDetail => suspectDetail.id !== curRecord.id
    );

    this.setState({ suspectDetailData });
    this.handleClose();
  };

  saveForm = () => {
    let hasError = this.state.hasError;
    let data = this.state.suspectDetailData;

    hasError = false

    this.props.updateHasError(this.state.activeStep, hasError)

    this.setState({
      hasError
    });

    this.props.updateData("suspectsFormData",data)
  };

  setupProgressLink = page => e => {
    e.preventDefault();
    this.goToPage(page);
  };

  goToPage = page => {
    this.saveForm();
    let link = "/" + removeWhiteSpace(page.toLowerCase());
    this.props.history.push(link);
  };

  goToNext = () => {
    let stepLink = [];
    steps.map(step => stepLink.push(removeWhiteSpace(step.toLowerCase())));

    let curPageUrl = this.props.location.pathname;
    curPageUrl = curPageUrl.slice(1);

    let curPos = stepLink.indexOf(curPageUrl);

    let link = stepLink[curPos + 1];

    this.goToPage(link);
  };

  checkHasError = (index) => {
    let hasError = false
    if(this.props.hasError[index]) {
      hasError = true
    }
    return hasError
  }

  goToRecord = id => e => {
    e.preventDefault();
    let curRecord = this.state.suspectDetailData.filter(
      suspect => suspect.id === id
    );
    this.setState({ curRecord: curRecord[0] });
    this.openSuspect();
  };

  render() {
    let { activeStep, isMobile, orientation, showSuspect } = this.state;

    const mandatoryStatement = (
      <p className="slight-bold mandatory-text">
        All fields are mandatory unless stated otherwise
      </p>
    );

    const dialogShowSuspect = (
      <Dialog
        scroll={"body"}
        open={this.state.showSuspect}
        onClose={this.handleClose}
        aria-labelledby="add-show-suspect"
        maxWidth={isMobile ? false : "md"}
        fullScreen={isMobile}
        className="form-control"
      >
        {this.state.curRecord.id && (
          <DialogActions>
            <Button onClick={this.handleDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        )}
        <DialogContent>
          <SuspectReport
            ref={this.suspectReportRef}
            data={this.state.curRecord}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );

    const content = (
      <>
        <article>
          <>
            <section className="form-control">
              <FormControl component="fieldset">
                <h2>Suspect information</h2>

                {this.state.suspectDetailData &&
                  this.state.suspectDetailData.length > 0 && (
                    <ul className="panel-container">
                      <li className="panel-header">
                        <span>First name</span>
                        <span>Last name</span>
                        <span>Email</span>
                        <span>Website</span>
                        <span>Business</span>
                      </li>
                      {this.state.suspectDetailData.map((suspect, i) => (
                        <li key={i} className="panel-body">
                          <a
                            href="#"
                            className="panel-link"
                            onClick={this.goToRecord(suspect.id)}
                          >
                            <span>{suspect.firstName}</span>
                            <span>{suspect.lastName}</span>
                            <span>{suspect.email}</span>
                            <span>{suspect.suspectWebsite}</span>
                            <span>{suspect.businessName}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                {this.state.suspectDetailData &&
                  this.state.suspectDetailData.length === 0 && (
                    <p>No suspect details on record</p>
                  )}
                {this.state.suspectDetailData &&
                  this.state.suspectDetailData.length < 10 && (
                    <div className="variantButton-container">
                      <Button
                        variant="outlined"
                        onClick={this.openSuspect}
                        color="primary"
                      >
                        Add a suspect
                      </Button>
                    </div>
                  )}

                <div className="button-group auto">
                  <button className="primary-btn" onClick={this.goToNext}>
                    Next
                  </button>
                </div>
              </FormControl>
            </section>
          </>
        </article>
      </>
    );

    return (
      <div className="bubble-contaner customStepper form-control">
        <Back link="intro" label="Back to Intro" />

        <Stepper activeStep={activeStep} orientation={orientation}>
          {steps.map((label, index) => {
            let labelProps = {}
            labelProps.error = this.checkHasError(index)
            return (<Step key={index}>
              <StepLabel className="stepLabel" {...labelProps}>
                <a href="#" onClick={this.setupProgressLink(label)}>
                  {label}
                </a>
              </StepLabel>
              {isMobile && <StepContent>{content}</StepContent>}
            </Step>
          )})}
        </Stepper>

        {!isMobile && content}

        {showSuspect && dialogShowSuspect}
      </div>
    );
  }
}

const ConnectedMainUpdate = props => (
  <MainConsumer>
  {({
    aboutYouData,
    businessDetailData,
    whatHappenedData,
    suspectsFormData,
    howDidItHappenData,
    updateData,
    updateHasError,
    hasError
  }) => (
    <SuspectDetail
      {...props}
      aboutYouData={aboutYouData}
      businessDetailData={businessDetailData}
      whatHappenedData={whatHappenedData}
      suspectsFormData={suspectsFormData}
      howDidItHappenData={howDidItHappenData}
      hasError={hasError}
      updateData={updateData}
      updateHasError={updateHasError}
    />
  )}
  </MainConsumer>
);
export default ConnectedMainUpdate;
