import React from 'react'
import { Link,  } from 'react-router-dom'

import './backButton.scss'

const BackButton = (props) => {
  const linkStyle = {
    marginTop:"1em"
  };

  return (
    <div className="backButton-container">
      <Link className="backButton-link" style={linkStyle}
        to={"/"+props.link}>
        {props.label || 'Back'}
      </Link>
    </div>
  )
}
export default BackButton
