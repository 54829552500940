import { bsbList } from "../../services/api/static"

/**
 * validate ABN
 *
 * 0. ABN must be 11 digits long
 * 1. Subtract 1 from the first (left) digit to give a new eleven digit number
 * 2. Multiply each of the digits in this new number by its weighting factor
 * 3. Sum the resulting 11 products
 * 4. Divide the total by 89, noting the remainder
 * 5. If the remainder is zero the number is valid
 */
export const isValidABN = abn => {
  var isValid = true;

  if (abn === null || abn === "") {
    isValid = true;
  } else {
    //remove all spaces
    abn = removeWhiteSpace(abn);
    //0. ABN must be 11 digits long
    isValid &= abn && /^\d{11}$/.test(abn);
    if (isValid) {
      var weightedSum = 0;
      var weight = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
      //Rules: 1,2,3
      for (var i = 0; i < weight.length; i++) {
        weightedSum += (parseInt(abn[i]) - (i === 0 ? 1 : 0)) * weight[i];
      }
      //Rules: 4,5
      isValid &= weightedSum % 89 === 0;
    }
  }
  return isValid;
};

export const isValidEmail = value => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
};

export const isABN = value => {
  return /^\d{11}$/.test(value);
};

export const isBSB = value => {
  return /^\d{6}$/.test(value);
};


export const sortBy = field => {
  return (a, b) => {
    if (a[field] && b[field]) {
      let aField = a[field];
      let bField = b[field];
      if (aField.toString().toLowerCase() < bField.toString().toLowerCase()) {
        return -1;
      } else if (
        aField.toString().toLowerCase() > bField.toString().toLowerCase()
      ) {
        return 1;
      }
    } else {
      return 0;
    }
  };
};

export const sortByDate = field => {
  return (a, b) => {
    if (a[field] && b[field]) {
      let aField = a[field];
      let bField = b[field];
      return new Date(bField) - new Date(aField);
    } else {
      return 0;
    }
  };
};

export const removeWhiteSpace = str => {
  return str.replace(/\s/g, "");
};

export const isBlank = val => {
  let isBlank = false;
  // catch null and undefined
  if (!val || JSON.stringify(val) === "{}") {
    isBlank = true;
  } else {
    if (typeof val === "string") {
      if (val.trim() === "" || val === "undefined") {
        isBlank = true;
      }
    }
    if (typeof val === "object") {
      if (Object.keys(val).length === 0) {
        isBlank = true;
      }
    }
  }
  return isBlank;
};

export const goToPage = (page, props) => {
  return (e) => {
    e.preventDefault()
    let link = "/" + removeWhiteSpace(page.toLowerCase())
    props.history.push(link)
  }
}

// convert to format accepted by RefDataSelect
export const convertToRef = (arrayData) => {
  let dataObjRef = []
  arrayData.map((data) => {
    let label = data
    let value = data
    let dataObj = {}
    dataObj.label = label
    dataObj.value = value
    dataObjRef.push(dataObj)
  })
  return dataObjRef
}

export const isValidIP = (ip) => {
  return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip))
}

export const isValidPhone = (phone) => {
  return ((/^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/).test(phone))
}
export const isValidPostcode = (postcode) => {
  return ((/^([0-9]){4}$/).test(postcode))
}
export const isValidWebsite = website => {
  return ((/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/).test(website))
}


// return an error object {hasError:true, errmsg: ""}
export const errorCheck = (name, value, allRules, isMan) => {
  let ruleObj = allRules.filter((suspect) => suspect.name === name)
  let errObj = {}

  if(ruleObj.length > 0) {
    let hasError = false
    let errMsg = ruleObj[0].errMsg

    if(isBlank(value)) {
      if(ruleObj.length > 0 && (ruleObj[0].isMan || isMan)) {
        hasError = true
        let obj = {}
      }
    } else {
      if(typeof(ruleObj[0].rule) === "function" && !ruleObj[0].rule(value)){
        hasError = true
      }
    }
    errObj.hasError = hasError
    errObj.errMsg = errMsg
  }
  return errObj
}

export const findBSBName = (searchBsb) => {

  // remove each number until there is a match
  let searchBsbString = searchBsb.toString()
    let found = ""
    for (let index = searchBsbString.length+1; index > 0; index-1){
      let newSearch = searchBsbString.substring(0,index)
      if(bsbList[newSearch]) {
        return found = bsbList[newSearch]
        break;
      }
      index--
    };
  }
