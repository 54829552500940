import React from "react";

import { Link } from "react-router-dom";

import LoadingSpinner from "./../../components/LoadingSpinner";
import * as api from "./../../services/api";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Back from "./../../components/BackButton";

import { MainConsumer } from "./../../providers/MainProvider";

import { steps } from "../../services/api/static";
import { removeWhiteSpace, isBlank } from "./../../services/helper";

import "./submit.scss";

class Submit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeStep: 0
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resize);
    this.resize();
    this.indexCurrentPage();
  };

  resize = () => {
    let isMobile = window.innerWidth <= 992;
    let orientation = isMobile ? "vertical" : "horizontal";
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile, orientation });
    }
  };

  setupProgressLink = page => e => {
    e.preventDefault();
    this.goToPage(page);
  };

  goToPage = page => {
    let link = "/" + removeWhiteSpace(page.toLowerCase());
    this.props.history.push(link);
  };

  checkHasError = index => {
    let hasError = false;
    if (this.props.hasError[index]) {
      hasError = true;
    }
    return hasError;
  };

  indexCurrentPage = () => {
    let curPage = this.props.location.pathname.slice(1);
    let activeStep;

    steps.map((step, i) => {
      if (curPage === removeWhiteSpace(step.toLowerCase())) {
        activeStep = i;
      }
    });

    this.setState({
      activeStep
    });
  };

  setupConfirmData = index => {
    let firstPage = this.props.aboutYouData;
    let secondPage = this.props.businessDetailData;
    let thirdPage = this.props.howDidItHappenData;
    let fourthPage = this.props.whatHappenedData;
    let fifthPage = this.props.suspectDetailData;

    switch (index) {
      case 1:
        return this.cleanPage(firstPage);
        break;
      case 2:
        return this.cleanPage(secondPage);
        break;
      case 3:
        return this.cleanPage(thirdPage);
        break;
      case 4:
        return this.cleanPage(fourthPage);
        break;
      case 5:
        return this.cleanPage(fifthPage);
        break;
      default:
    }
  };

  cleanPage = () => {
    let firstPageContent = (
      <>


      <div className="summary-row three-columns">
        <div className="summary-cell"><span className="label">First name</span><span>Cindy</span></div>
        <div className="summary-cell"><span className="label">Last name</span><span>Pak</span></div>
        <div className="summary-cell"><span className="label">Street address</span><span>1 Langton Crescent Parkes 2600 ACT</span></div>

      </div>
      <div className="summary-row three-columns">
        <div className="summary-cell"><span className="label">Gender</span><span>Female</span></div>
        <div className="summary-cell"><span className="label">Date of birth</span><span>16/04/1990</span></div>
        <div className="summary-cell"><span className="label">Mobile</span><span>0262633172</span></div>
      </div>
      <div className="summary-row three-columns">
      </div>
      <div className="summary-row three-columns">
        <div className="summary-cell"><span className="label">Business role</span><span>Secretary</span></div>
        <div className="summary-cell"><span className="label">Torres Strait Islander</span><span>Prefer not to answer</span></div>
        <div className="summary-cell"><span className="label">Email</span><span>pak.cindy@gmail.com</span></div>
      </div>


      </>
    );

    return firstPageContent;
  };

  handleSubmit = () => {
    this.props.history.push("/thankyou");
  };

  render() {
    const content = (
      <>
        <div className="submit-container form-control confirm-submission">
          <LoadingSpinner laoding={this.state.loading}>
            <>
              <h2>Confirm report details</h2>

              {steps.map((step, i) => {
                let stepLink = removeWhiteSpace(step.toLowerCase());

                return (
                  <section key={i}>
                      <h4><Link to={stepLink}>{step}</Link></h4>
                        <div className="summary-group">
                          {this.cleanPage(i)}
                        </div>
                  </section>
                );
              })}

              <div className="button-group">
                <button className="action-btn" onClick={this.handleSubmit}>
                  Submit
                </button>
              </div>
            </>
          </LoadingSpinner>
        </div>
      </>
    );

    let { activeStep, isMobile, orientation } = this.state;

    return (
      <div className="bubble-contaner customStepper form-control">
        <Back link="intro" label="Back to Intro" />

        <Stepper activeStep={activeStep} orientation={orientation}>
          {steps.map((label, index) => {
            let labelProps = {};
            labelProps.error = this.checkHasError(index);
            return (
              <Step key={index}>
                <StepLabel className="stepLabel" {...labelProps}>
                  <a href="#" onClick={this.setupProgressLink(label)}>
                    {label}
                  </a>
                </StepLabel>
                {isMobile && <StepContent>{content}</StepContent>}
              </Step>
            );
          })}
        </Stepper>

        {!isMobile && content}
      </div>
    );
  }
}

const ConnectedMainUpdate = props => (
  <MainConsumer>
    {({
      aboutYouData,
      businessDetailData,
      whatHappenedData,
      suspectsFormData,
      howDidItHappenData,
      updateData,
      updateHasError,
      hasError
    }) => (
      <Submit
        {...props}
        aboutYouData={aboutYouData}
        businessDetailData={businessDetailData}
        whatHappenedData={whatHappenedData}
        suspectsFormData={suspectsFormData}
        howDidItHappenData={howDidItHappenData}
        hasError={hasError}
        updateData={updateData}
        updateHasError={updateHasError}
      />
    )}
  </MainConsumer>
);
export default ConnectedMainUpdate;
