import React from "react";
import deburr from "lodash/deburr";
import Downshift from "downshift";
import { withStyles } from "@material-ui/core/styles";
import TextField from "./../../components/TextField";
import RefDataSelector from "./../../components/RefDataSelector";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import * as api from "./../../services/api";
import {
  removeWhiteSpace,
  isBlank,
  errorCheck,
  isValidPostcode
} from "./../../services/helper";
import { countries, australianState } from "../../services/api/static";
import "./address.scss";

const findAddressText = "Find the address";
const cantFindAddressText = "I can't find the address";

const allRules = [
  { name: "country", isMan: false, rule: null, errMsg: "Provide country" },
  { name: "street", isMan: false, rule: null, errMsg: "Provide street" },
  { name: "state", isMan: false, rule: null, errMsg: "Provide state" },
  { name: "city", isMan: false, rule: null, errMsg: "Provide city" },
  { name: "unit", isMan: false, rule: null, errMsg: "Provide unit" },
  { name: "suburb", isMan: false, rule: null, errMsg: "Provide suburb" },
  { name: "zipcode", isMan: false, rule: null, errMsg: "Provide zipcode" },
  {
    name: "postcode",
    isMan: false,
    rule: null,
    errMsg: "provide postcode"
  }
];

class Address extends React.Component {
  state = {
    inputValue: this.props.value,
    selectedItem: this.props.value,
    suggestions: [],
    textvalue: null,
    enterManually: false,
    enterMauallyText: cantFindAddressText,
    hasError: this.props.error,
    helperText: this.props.helperText,
    isMandatory: this.props.isMandatory
  };

  static getDerivedStateFromProps(props, state) {
    if (props.error !== state.error) {
      return {
        error: props.error
      };
    }
    if (props.helperText !== state.helperText) {
      return {
        helperText: props.helperText
      };
    }
    if (props.value !== state.value) {
      return {
        inputValue: props.value,
        selectedItem:props.value
      };
    }
    return null;
  }

  renderInput = inputProps => {
    const { InputProps, classes, id, ...other } = inputProps;
    const { error, helperText } = this.props;
    const { hasError } = this.state;

    return (
      <TextField
        helperText={helperText}
        error={error}
        InputLabelProps={{
          required: false
        }}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput
          },
          ...InputProps
        }}
        {...other}
      />
    );
  };

  renderSuggestion = ({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  }) => {
    const isHighlighted = highlightedIndex === index;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.address}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: 400
        }}
      >
        {suggestion.address}
      </MenuItem>
    );
  };

  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.state;
    if (selectedItem && event.keyCode !== 9) {
      this.setState({
        selectedItem: null,
        inputValue: ""
      });
    }
  };

  handleInputChange = async e => {
    let inputValue = e.target.value;
    let suggestions = [];
    this.setState({
      inputValue
    });

    if (inputValue && removeWhiteSpace(inputValue).length > 2) {
      let res = await api.psmaSearch(inputValue);
      suggestions = res.suggest;
    }

    this.setState({
      suggestions
    });
    this.props.updateAddress(inputValue);
  };

  onSuggestionSelected = selectedItem => {
    let hasError = false;
    this.setState({
      inputValue: selectedItem,
      selectedItem,
      hasError
    });
    this.props.updateAddress(selectedItem);
  };

  toggleEnterManually = e => {
    e.preventDefault();
    this.setState((prevState, props) => ({
      enterManually: !prevState.enterManually,
      enterMauallyText: prevState.enterManually
        ? cantFindAddressText
        : findAddressText
    }));
  };

  handleRefSelectorChange = name => {
    return selected => {
      this.setState({
        [name + "Error"]: false,
        [name + "ErrMsg"]: ""
      });

      this.setState({
        [name]: selected
      });
    };
  };

  handleBlur = name => e => {
    let errObj = errorCheck(
      name,
      e.target.value,
      allRules,
      this.props.isMandatory
    );
    let hasError = errObj && errObj.hasError;
    let errMsg = errObj && errObj.errMsg;

    this.setState({
      [name + "Error"]: hasError,
      [name + "ErrMsg"]: hasError ? errMsg : ""
    });
  };

  handleChange = name => e => {
    // reset error
    this.setState({
      [name + "Error"]: false,
      [name + "ErrMsg"]: ""
    });

    let value = e.target.value;
    let errObj = errorCheck(name, value, allRules, this.props.isMandatory);
    let hasError = errObj && errObj.hasError;
    let errMsg = errObj && errObj.errMsg;

    this.setState({
      [name]: value,
      [name + "Error"]: hasError,
      [name + "ErrMsg"]: hasError ? errMsg : ""
    });
  };

  render() {
    const {
      classes,
      ref,
      label,
      localOnly,
      required,
      helperText,
      isMobile
    } = this.props;
    const {
      inputValue,
      selectedItem,
      suggestions,
      enterMauallyText,
      enterManually,
      hasError,
      country
    } = this.state;

    return (
      <div className="address-container">
        {!enterManually && (
          <Downshift
            inputValue={inputValue}
            onChange={this.onSuggestionSelected}
            selectedItem={selectedItem}
          >
            {({
              getInputProps,
              getItemProps,
              isOpen,
              inputValue,
              selectedItem,
              highlightedIndex
            }) => (
              <div className={classes.container}>
                {this.renderInput({
                  fullWidth: true,
                  classes,
                  InputProps: getInputProps({
                    onChange: this.handleInputChange,
                    onKeyDown: this.handleKeyDown
                  }),
                  label
                })}
                {isOpen && suggestions.length > 0 ? (
                  <Paper className={classes.paper} square>
                    {suggestions &&
                      suggestions.map((suggestion, index) =>
                        this.renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({ item: suggestion.address }),
                          highlightedIndex,
                          selectedItem
                        })
                      )}
                  </Paper>
                ) : null}
              </div>
            )}
          </Downshift>
        )}

        {enterManually && (
          <>
            {localOnly && (
              <>
                <div className="readOnly">
                  <span>Country: Australia </span>
                </div>
                <div className="enterManually-container half-area">
                  <TextField
                    error={this.state.unitError}
                    helperText={this.state.unitErrMsg}
                    onBlur={this.handleBlur("unit")}
                    onChange={this.handleChange("unit")}
                    label="Level, Unit or Number"
                    autoFocus={true}
                  />
                  <TextField
                    error={this.state.streetError}
                    helperText={this.state.streetErrMsg}
                    onBlur={this.handleBlur("street")}
                    onChange={this.handleChange("street")}
                    label="Street name"
                  />
                  <TextField
                    error={this.state.suburbError}
                    helperText={this.state.suburbErrMsg}
                    onBlur={this.handleBlur("suburb")}
                    onChange={this.handleChange("suburb")}
                    label="Suburb"
                  />
                  <TextField
                    error={this.state.postcodeError}
                    helperText={this.state.postcodeErrMsg}
                    onBlur={this.handleBlur("postcode")}
                    onChange={this.handleChange("postcode")}
                    label="Post code"
                    onInput={e => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 4);
                    }}
                    min={0}
                    type="number"
                  />
                </div>
              </>
            )}
            {!localOnly && (
              <div className="enterManually-container half-area">
                <RefDataSelector
                  label="Country"
                  id="country"
                  name="country"
                  data={countries}
                  onChange={this.handleRefSelectorChange("country")}
                  error={this.state.countryError}
                  helperText={this.state.countryErrMsg}
                  autoFocus={true}
                />

                <TextField
                  error={this.state.streetError}
                  helperText={this.state.streetErrMsg}
                  onBlur={this.handleBlur("street")}
                  onChange={this.handleChange("street")}
                  label="Street address"
                />
                <TextField
                  error={this.state.cityError}
                  helperText={this.state.cityErrMsg}
                  onChange={this.handleChange("city")}
                  onBlur={this.handleBlur("city")}
                  label="City"
                />
                {this.state.country === "Australia" && (
                  <RefDataSelector
                    label="State"
                    id="state"
                    name="state"
                    data={australianState}
                    onChange={this.handleRefSelectorChange("state")}
                    onBlur={this.handleBlur("state")}
                    error={this.state.stateError}
                    helperText={this.state.stateErrMsg}
                  />
                )}
                <TextField
                  onChange={this.handleChange("state")}
                  onBlur={this.handleBlur("state")}
                  error={this.state.stateError}
                  helperText={this.state.stateErrMsg}
                  label="State / Province / Region"
                />
                <TextField
                  required
                  InputLabelProps={{ required: false }}
                  onChange={this.handleChange("zipcode")}
                  onBlur={this.handleBlur("zipcode")}
                  error={this.state.zipcodeError}
                  helperText={this.state.zipcodeErrMsg}
                  onInput={e => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                  }}
                  min={0}
                  type="number"
                  label="Zip Code"
                />
              </div>
            )}
          </>
        )}

        <a
          href="#"
          className="manualAddress"
          onClick={this.toggleEnterManually}
        >
          {enterMauallyText}
        </a>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    position: "absolute",
    zIndex: "1!important",
    marginTop: theme.spacing.unit,
    top: "33px",
    left: 0,
    right: 0
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  inputRoot: {
    flexWrap: "wrap"
  },
  inputInput: {
    width: "auto",
    flexGrow: 1
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

export default withStyles(styles)(Address);
