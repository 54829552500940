import React from 'react'
import Back from './../../components/BackButton'
import Address from './../../components/Address'
import LoadingSpinner from './../../components/LoadingSpinner'

import './styleguide.scss'

let content = <section className="form-control">
  <button className="action-btn">Submit</button>
  <button className="primary-btn">Next</button>
  <button className="tertiary-btn">Cancel</button>
  <button className="inactive-btn">Inactive</button>
  <h2>Business detail - International Address</h2>
  <Address label="Address" required={true} InputLabelProps={{ required: false }} localOnly={false} helperText="International Address required" />
  <h2>Local Address</h2>
  <Address label="Address" required={true} InputLabelProps={{ required: false }} localOnly={true} helperText="Australian Address required" />
  <h2>Spinner</h2>
  <LoadingSpinner loading={true} />

  <h1>h1. Heading</h1>
  <h2>h2. Heading</h2>
  <h3>h3. Heading</h3>
  <h4>h4. Heading</h4>
  <h5>h5. Heading</h5>
  <h6>h6. Heading</h6>
  <span>body. text</span>
</section>

class Styleguide extends React.Component {

  render(){
    return (
      <div className="styleguide-container">
        <Back props={this.props} />

        <h1>Styleguide Component</h1>
        {content}


      </div>
    )
  }
}
export default Styleguide
