import React from "react";
import TextField from "./../../components/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import { emailReportMandatory } from "../../services/api/static";

import { isBlank, isValidIP, errorCheck, isValidEmail, isValidWebsite } from "./../../services/helper";

import "./emailDetail.scss";

const allRules = [
  {
    name: "senderEmailAddress",
    isMan: true,
    rule: isValidEmail,
    errMsg: "Provide valid email"
  },
  {
    name: "subjectLine",
    isMan: false,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "dateReceived",
    isMan: true,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "ipSentFrom",
    isMan: false,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "websiteLink",
    isMan: false,
    rule: isValidWebsite,
    errMsg: "Provide valid website link"
  },
  {
    name: "imitatedEmail",
    isMan: true,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "emailTakenOver",
    isMan: true,
    rule: null,
    errMsg: "Provide answer"
  }
];


class EmailDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasImitateEmail: null,
      emailTakenOver: null,
      scamEmailData: props.data ? props.data : []
    };
  }

  componentDidMount = () => {
    if (this.props.data !== null) {
      this.setState({
        ...this.props.data
      });
    }
  };

  getData = () => {
    let emailDetailData = {};
    let id = this.props.data ? this.props.data.id : null;

    const {
      senderEmailAddress,
      subjectLine,
      dateReceived,
      ipSentFrom,
      websiteLink,
      imitatedEmail,
      emailTakenOver
    } = this.state;
    emailDetailData = {
      senderEmailAddress,
      subjectLine,
      dateReceived,
      ipSentFrom,
      websiteLink,
      imitatedEmail,
      emailTakenOver,
      id
    };

    return emailDetailData;
  };

  handleBlur = name => e => {
    let errObj = errorCheck(name, e.target.value, allRules);
    let hasError = errObj && errObj.hasError;
    let errMsg = errObj && errObj.errMsg;

    this.setState({
      [name + "Error"]: hasError,
      [name + "ErrMsg"]: hasError ? errMsg : ""
    });
  };

  handleChange = name => e => {
    // reset error
    this.setState({
      [name + "Error"]: false,
      [name + "ErrMsg"]: ""
    });

    let value = e.target.value;

    this.setState({
      [name]: value
    });
  };

  render() {
    let { hasImitateEmail, emailTakenOver } = this.state;

    const content = (
      <section className="half-area">
        <h2>Scam email details</h2>
        <TextField
          id="senderEmailAddress"
          label="Senders email address"
          value={this.state.senderEmailAddress}
          error={this.state.senderEmailAddressError}
          helperText={this.state.senderEmailAddressErrMsg}
          onBlur={this.handleBlur("senderEmailAddress")}
          onChange={this.handleChange("senderEmailAddress")}
        />
        <TextField
          id="subjectLine"
          label=<span>Subject line<span className="slight-bold"> [Optional]</span></span>
          value={this.state.subjectLine}
          error={this.state.subjectLineError}
          helperText={this.state.subjectLineErrMsg}
          onBlur={this.handleBlur("subjectLine")}
          onChange={this.handleChange("subjectLine")}
        />
        <TextField
          id="dateReceived"
          type="date"
          label="Date received"
          value={this.state.dateReceived}
          error={this.state.dateReceivedError}
          helperText={this.state.dateReceivedErrMsg}
          onBlur={this.handleBlur("dateReceived")}
          onChange={this.handleChange("dateReceived")}
        />
        <TextField
          id="ipSentFrom"
          label=<span>IP address email was sent from<span className="slight-bold"> [Optional]</span></span>
          value={this.state.ipSentFrom}
          error={this.state.ipSentFromError}
          helperText={this.state.ipSentFromErrMsg}
          onBlur={this.handleBlur("ipSentFrom")}
          onChange={this.handleChange("ipSentFrom")}
        />
        <TextField
          label=<span>Website link <span className="slight-bold"> [Optional]</span></span>
          id="websiteLink"
          type="url"
          value={this.state.websiteLink}
          error={this.state.websiteLinkError}
          helperText={this.state.websiteLinkErrMsg}
          onBlur={this.handleBlur("websiteLink")}
          onChange={this.handleChange("websiteLink")}
        />
        <FormLabel component="legend" className="leftAligned-question">
          Was the email address used to contact you made to look similar to a
          real email address?
        </FormLabel>
        <RadioGroup
          aria-label="Imitated email"
          name="imitatedEmail"
          ref="imitatedEmail"
          value={this.state.imitatedEmail}
          onChange={this.handleChange("imitatedEmail")}
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label="Yes"
            labelPlacement="end"
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label="No"
            labelPlacement="end"
          />
        </RadioGroup>
        <FormLabel component="legend" className="leftAligned-question">
          Was the email sent from a legitimate email address that may have been
          taken over?
        </FormLabel>
        <RadioGroup
          aria-label="Email taken over"
          name="emailTakenOver"
          ref="emailTakenOver"
          value={this.state.emailTakenOver}
          onChange={this.handleChange("emailTakenOver")}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel
            value="unsure"
            control={<Radio />}
            label="I don't know"
          />
        </RadioGroup>
      </section>
    );
    return <div className="emailDetail-contaner">{content}</div>;
  }
}
export default EmailDetail;
