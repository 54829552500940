import React from "react";
import ASDLogo from './asd-logo.png'
import ACSCLogo from './acsc-logo.jpg'

import MenuBurger from './../MenuBurger'
import MenuBurgerWrapper from './../MenuBurgerWrapper'

import './header.scss'

const headerLabel = "Reporting an issue"

const topNavMenu = [
  {"link":"individual","label":"Individual","hasChild":true,"isRoot":true},
  {"link":"business","label":"Business","hasChild":true,"isRoot":true},
  {"link":"government","label":"Government","hasChild":true,"isRoot":true},
  {"link":"infrastructure","label":"Infrastructure","hasChild":true,"isRoot":true},
  {"link":"about-this-site","label":"About ACSC","hasChild":false,"isRoot":true}
]

const subMenu = [
  {"link":"news","label":"News", "except":[]},
  {"link":"threats","label":"Threats","except":[]},
  {"link":"guides","label":"Advice","except":[]},
  {"link":"programs","label":"Programs","except":["individual"]},
  {"link":"publications","label":"Publications","except":[]},
  {"link":"faqs","label":"FAQ","except":[]},
  {"link":"report","label":"Get Help","except":[]}
]

class Header extends React.Component {
  constructor(props){
    super(props);
    this.state={
      isMenuOpened:false,
      parentLabel:"business"
    }
  }

  setMenuOpen = () => {
    this.setState((prevState, props) => ({
      isMenuOpened: !prevState.isMenuOpened
    }))
  }

  goToPage = (menuObj, isRoot) => {
    return(e) => {
      e.preventDefault()
      const baseURL = "http://cyber.gov.au"
      const parentLink = this.state.parentLabel.toLowerCase()
      const baseParentURL = baseURL + '/' + parentLink
      const rootLink = baseURL + '/' + menuObj
      let goToLink = ""

      goToLink = isRoot === true ? rootLink : baseParentURL + '/' + menuObj

      return window.location.href = goToLink
    }
  }

  render(){

    return (
      <header id="header-main" className="header-main" role="banner">
        <section>
          <nav className="uikit-skip-link">
            <a className="uikit-skip-link__link" href="#content">Skip to main content</a>
          </nav>
          <div className="header-container">
            <div className="top-nav">
              <nav role="navigation">
                <ul>
                  {topNavMenu && topNavMenu.map((topNav, i) =>
                    <li key={i}>
                      <a href="#" onClick={this.goToPage(topNav.link, true)}><span>{topNav.label}</span></a>
                    </li>
                  )}
                  <li className="right"><a href="">cyber.gov.au
                    <span className="bubble">alpha</span></a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="logo-container">
              <div className="logo-asd">
                <h1>
                  <a href="asd.gov.au" title="Go to Australian Signals Directorate Main">
                    <img border="0" src={ASDLogo} alt="Australian Signals Directorate" id="ASDLogo" title="Australian Signals Directorate logo" />
                    <span className="is-visuallyhidden">Australian Signals Directorate</span>
                  </a>
                </h1>
              </div>
              <div className="logo-cyber">
                <h1>
                  <a href="cyber.gov.au" title="Go to Australian Cyber Security Centre Main">
                    <img border="0" src={ACSCLogo} alt="Australian Cyber Security Centre" id="acscLogo" title="Australian Cyber Security Centre logo" />
                    <span className="is-visuallyhidden">Australian Cyber Security Centre</span>
                  </a>
                </h1>
              </div>
            </div>
          </div>
          <div className="header-secondaryNav">
            <div className="header-container">
              <h1>{headerLabel}</h1>
              <div className="mobile-menu mobileOnly">
                <MenuBurger open={this.state.isMenuOpened} onClick={this.setMenuOpen} />
              </div>
              <div className="mobileOnly">
                {this.state.isMenuOpened &&
                  <MenuBurgerWrapper topNavMenu={topNavMenu} subMenu={subMenu} setMenuOpen={this.setMenuOpen} />
                }
              </div>
              <nav role="navigation" id="content" className="header-subMenu">
                <ul>
                  {subMenu && subMenu.map((subMenuItem, i) =>
                    <li key={i}>
                      <a href="#" onClick={this.goToPage(subMenuItem.link, false)}>
                        <span>{subMenuItem.label}</span>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </section>
      </header>
    );
  };
}
export default Header;
