import Amplify, { API } from 'aws-amplify';
import aws_exports from './../../../src/aws-exports'
import axios from 'axios'
import allStaticData from './static'
import observer from 'node-observer'

// to trigger spinner on post, use following
// observer.send('loading-sender', 'section-loading', {loading:true})


// Need new amplify config files
// Amplify.configure(aws_exports)
// let URL_BASE = aws_exports.aws_cloud_logic_custom[0].name
//
// export const getUriBase = () => {
//   const urlBase = URL_BASE
//   return urlBase
// }
//
// export const getUserPreference = async () => {
//   const userPreference = await API.get(URL_BASE, '/reports/user' )
//   return userPreference
// }
//
// export const getQueue = async () => {
//   const queue = await API.get(URL_BASE, '/reports/queue')
//   return queue
// }

export const psmaSearch = async (r) => {
  const key = 'H5srUAXR7WxQ8sNv6YpzOvrcrL71aOvz'
  const maxResults = 4
  const domain = 'https://api.psma.com.au'
  const request = domain + '/beta/v1/predictive/address/?query=' + r + '&stateTerritory=ALL' + '&maxNumberOfResults=' + maxResults

  const res = await axios.get(request, {headers: {'Authorization': key}},)
  return await res.data
}

export const psmaGet = async (uuid) => {
  const key = 'H5srUAXR7WxQ8sNv6YpzOvrcrL71aOvz'
  const domain = 'https://api.psma.com.au'
  const request = domain + '/beta/v1/predictive/address/'+uuid+'/'

  const res = await axios.get(request, {headers: {'Authorization': key}},)
  return await res.data
}

export const abnGet = async (abn) => {
  observer.send('loading-sender', 'section-loading', {loading:true})
  const domain = 'abr.business.gov.au/json/AbnDetails.aspx?abn='
  const request = 'https://cors-anywhere.herokuapp.com/' + domain +abn+'&guid=df84a7e0-e4cc-4d91-8d63-33e32dc26bbc'

  let result = null

  const res = await axios.get(request)
  if(res.status === 200) {
    let resultData = JSON.parse(res.data.replace(/^callback\(|\)$/g, ''));
    result = resultData
  }
  observer.send('loading-sender', 'section-loading', {loading:false})

  return result
}
