import React from 'react'

import './thankyou.scss'

class Thankyou extends React.Component {

  constructor(props) {
      super(props)
      this.state = {
      }
  }


  render() {
    return (
      <div className="thankyou-container">
        <h2>Thank you for your report</h2>

        <p>some very useful links</p>
      </div>
    )
  }
}
export default Thankyou
