import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from './../../components/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import './refDataSelector.scss'

// Polyfill https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/isArray#Polyfill
if (!Array.isArray) {
  Array.isArray = function(arg) {
    return Object.prototype.toString.call(arg) === '[object Array]';
  };
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: '12px 14px',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
    paddingLeft:"7px"
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    color: "#62647E!important",
    paddingLeft:'20px'
  },
  paper: {
    marginTop: theme.spacing.unit,
    padding: '0!important',
    fontSize:16,
    fontFamily:'"mr-eaves-xl-modern", "roboto", "helvetica", sans-serif'
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          ref: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
  Menu,
};

class RefDataSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      single: null,
      display:true,
      options:[],
      value:''
    }
  }

  componentDidMount() {
    this.setState({value: this.props.value || ''})
    this.loadData()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data || props.value !== state.value) {
      return {
        value: props.value,
        data: props.data
      };
    }
    return null;
  }

  loadData = () => {
      let data = this.props.data
      this.setState({
        options: data
      })
      if(data && data.length === 1){
        let value = data[0]
        this.setState({
          value
        })
        this.props.onChange(value)
      }
      if(data && data.length === 0){
        this.setState({
          display: false
        })
        if (this.props.onChange) {
          this.props.onChange('')
        }
      }
      if(this.props.value){
        let text = ''
        if(this.props.value.value){
          text = data.find((item) =>  item.value.toLowerCase() === this.props.value.value.toLowerCase())
        } else {
          text = data.filter((item) =>  this.props.value.indexOf(item.value) > -1)
        }
        if(text){
          this.setState({
            value: text
          })
        }
      }
      return
  }

  onChange = name => value => {
    this.setState({
      [name]: value,
    });
    this.props.onChange(value)
  };

  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
      }),
    };

    if(!this.state.display){
      return null
    }

    return (
      <div className="refDataSelector-container">
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            options={this.state.options}
            components={components}
            value={this.state.value}
            onChange={this.onChange('value')}
            isClearable={true}
            textFieldProps={{
              label: this.props.label,
              InputLabelProps: {
                shrink: true,
              },
            }}
            placeholder={this.props.placeholder || 'Select...'}
          />

        </NoSsr>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(RefDataSelector);
