import React from "react";
import { Link } from "react-router-dom";
import TextField from "./../../components/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Address from "./../../components/Address";
import RefDataSelector from "./../../components/RefDataSelector";
import MoneyFromTo from "./../../components/MoneyFromTo";
import Stepper from "@material-ui/core/Stepper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Back from "./../../components/BackButton";
import DialogActions from "@material-ui/core/DialogActions";

import { MainConsumer } from "./../../providers/MainProvider";

import {
  removeWhiteSpace,
  isBlank,
  errorCheck
} from "./../../services/helper";
import { steps, payment, paymentMethod } from "../../services/api/static";

import "./whatHappened.scss";

const allFields = [
  {
    name: "hasReportedFinancial",
    isMan: false,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "hasFinancialResolved",
    isMan: false,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "hasReportedPolice",
    isMan: false,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "policeReportReference",
    isMan: false,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "hasLostMoney",
    isMan: false,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "hasLostInfo",
    isMan: false,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "hasLostIp",
    isMan: false,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "hasLostPi",
    isMan: false,
    rule: null,
    errMsg: "Provide answer"
  },
  {
    name: "moneyFromToData",
    isMan: false,
    rule: null,
    errMsg: "Provide answer"
  }
];

class WhatHappened extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      isMobile: false,
      orientation: "horizontal",
      showPaymentTransaction: false,
      moneyFromToData: [],
      hasLostMoney: false,
      hasLostInfo: false,
      hasLostPi: false,
      hasLostIp: false,
      curRecord: {}
    };
    this.moneyFromToRef = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resize);
    this.resize();
    this.indexCurrentPage();
    if (!isBlank(this.props.whatHappenedData)) {
      this.setState({
        ...this.props.whatHappenedData
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.whatHappenedData !== state.whatHappenedData) {
      return {
        whatHappenedData: props.whatHappenedData
      };
    }
    return null;
  }

  indexCurrentPage = () => {
    let curPage = this.props.location.pathname.slice(1);
    let activeStep;

    steps.map((step, i) => {
      if (curPage === removeWhiteSpace(step.toLowerCase())) {
        activeStep = i;
      }
    });

    this.setState({
      activeStep
    });
  };

  resize = () => {
    let isMobile = window.innerWidth <= 992;
    let orientation = isMobile ? "vertical" : "horizontal";
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile, orientation });
    }
  };

  handleChange = stateValue => e => {
    this.setState({
      [stateValue]: e.target.value
    });
  };

  handleCheck = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleRefSelectorChange = name => selected => {
    this.setState({
      [name + "Error"]: false,
      [name + "ErrMsg"]: ""
    });

    this.setState({
      [name]: selected
    });
  };

  // unique set of bsb+account+name, to make it easier for form filling
  setupUniqueBSBAccountName = () => {
    let uniqueBankFrom = [];
    let alreadyExists = false;

    this.state.moneyFromToData.map(money => {
      if (
        !isBlank(money.bsb) &&
        !isBlank(money.accountNumber) &&
        !isBlank(money.accountName)
      ) {
        let newObj = {};
        let uniqueKey = money.bsb + money.accountNumber + money.accountName;

        if (uniqueBankFrom.length === 0) {
        } else {
          uniqueBankFrom.map(
            moneyFrom =>
              (alreadyExists =
                moneyFrom.bsb +
                  moneyFrom.accountNumber +
                  moneyFrom.accountName ===
                uniqueKey
                  ? true
                  : alreadyExists)
          );
        }
        if (!alreadyExists) {
          uniqueBankFrom.push(money);
        }
      }
    });
    this.setState({ uniqueBankFrom });
    return uniqueBankFrom;
  };

  // unique set of transferred to account, to make it easier for form filling
  setupUniqueBankTo = () => {
    let uniqueBankTo = [];
    let alreadyExists = false;

    this.state.moneyFromToData.map(money => {
      if (!isBlank(money.payID) || !isBlank(money.suspectAccountNumber)) {
        let newObj = {};
        let uniqueKey = "";

        uniqueKey = !isBlank(money.payID)
          ? money.payIDType + money.payID
          : money.suspectAccountName +
            money.suspectBSB +
            money.suspectAccountNumber;

        if (uniqueBankTo.length === 0) {
        } else {
          uniqueBankTo.map(
            bankTo =>
              (alreadyExists =
                bankTo.payIDType + bankTo.payID === uniqueKey ||
                bankTo.suspectAccountName +
                  bankTo.suspectBSB +
                  bankTo.suspectAccountNumber ===
                  uniqueKey
                  ? true
                  : alreadyExists)
          );
        }
        if (!alreadyExists) {
          uniqueBankTo.push(money);
        }
      }
    });
    this.setState({ uniqueBankTo });
    return uniqueBankTo;
  };

  handleSave = () => {
    // could be new or edit
    let moneyFromToData = this.state.moneyFromToData;

    let newMoneyFromToObj = this.moneyFromToRef.current.getData();
    if (newMoneyFromToObj.id) {
      moneyFromToData = moneyFromToData.map(moneyFromTo =>
        moneyFromTo.id === newMoneyFromToObj.id
          ? (moneyFromTo = newMoneyFromToObj)
          : moneyFromTo
      );
    } else {
      let id = moneyFromToData.length + 1;
      newMoneyFromToObj.id = id;
      moneyFromToData.push(newMoneyFromToObj);
    }

    this.setState({ moneyFromToData });

    this.handleClose();
  };

  handleClose = () => {
    let showPaymentTransaction = false;
    let curRecord = [];
    this.setState({ showPaymentTransaction, curRecord });
  };

  openPaymentTransaction = () => {
    let showPaymentTransaction = true;
    let uniqueBankFrom = this.setupUniqueBSBAccountName();
    let uniqueBankTo = this.setupUniqueBankTo();
    this.setState({ showPaymentTransaction, uniqueBankFrom, uniqueBankTo });
  };

  handleDelete = () => {
    let moneyFromToData = this.state.moneyFromToData;
    let curRecord = this.state.curRecord;
    moneyFromToData = moneyFromToData.filter(
      moneyFromTo => moneyFromTo.id !== curRecord.id
    );

    this.setState({ moneyFromToData });
    this.handleClose();
  };

  validateForm = () => {
    let hasError = false;
    let errObjArray = [];
    allFields.map(field => {
      let stateValue = this.state[field.name]

      let errorCheckObj = errorCheck(field.name, stateValue, allFields);
      let hasErrorObj = {};
      hasErrorObj = isBlank(errorCheckObj)
        ? errorCheckObj
        : errObjArray.push(errorCheckObj);
      hasError = hasError ? errorCheckObj.hasError : hasError;
      this.setState({
        [field.name + "Error"]: hasError,
        [field.name + "ErrMsg"]: hasError ? field.errMsg : ""
      });
    });
    return hasError;
  };

  saveForm = () => {
    let hasError = this.state.hasError;
    let data = {};


    allFields.map(
      field => {
        let stateValue = this.state[field.name];
        data[field.name] = stateValue
      }
    );

    hasError = this.validateForm();

    this.props.updateHasError(this.state.activeStep, hasError)

    this.setState({
      data: data,
      hasError
    });

    this.props.updateData("whatHappenedData",data)
  };

  setupProgressLink = (page) => e => {
    e.preventDefault();
    this.goToPage(page)
  }

  goToPage = (page) =>  {
    this.saveForm();
    let link = "/" + removeWhiteSpace(page.toLowerCase());
    this.props.history.push(link);
  };

  goToNext = () => {
    let stepLink = []
    steps.map((step) =>
      stepLink.push(removeWhiteSpace(step.toLowerCase()))
    )

    let curPageUrl = this.props.location.pathname
    curPageUrl = curPageUrl.slice(1)

    let curPos = stepLink.indexOf(curPageUrl)

    let link = stepLink[curPos+1]

    this.goToPage(link)
  }

  checkHasError = (index) => {
    let hasError = false
    if(this.props.hasError[index]) {
      hasError = true
    }
    return hasError
  }

  goToRecord = id => e => {
    e.preventDefault();

    let curRecord = this.state.moneyFromToData.filter(
      moneyFromTo => moneyFromTo.id === id
    );
    this.setState({ curRecord: curRecord[0] });
    this.openPaymentTransaction();
  };

  render() {
    let {
      activeStep,
      isMobile,
      orientation,
      hasReportedPolice,
      policeReportReference,
      hasLostIp,
      hasLostPi,
      uniqueBankFrom,
      hasLostMoney,
      hasLostInfo,
      showPaymentTransaction
    } = this.state;

    const mandatoryStatement = (
      <p className="slight-bold mandatory-text">
        All fields are mandatory unless stated otherwise
      </p>
    );

    const otherMoneyFromToDetail = (
      <section>
        <div className="half-area">
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Reported to financial institution
            </FormLabel>
            <RadioGroup
              aria-label="reported"
              name="reported"
              value={this.state.hasReportedFinancial}
              onChange={this.handleChange("hasReportedFinancial")}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes, has been reported to financial institute"
              />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          {this.state.hasReportedFinancial === "yes" && (
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Financial Institution has resolved the issue
              </FormLabel>
              <RadioGroup
                aria-label="resolved"
                name="resolved"
                value={this.state.hasFinancialResolved}
                onChange={this.handleChange("hasFinancialResolved")}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes, this has been resolved"
                />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          )}
          <FormControl component="fieldset">
            <FormLabel component="legend">Reported to police</FormLabel>
            <RadioGroup
              aria-label="reported"
              name="reported"
              value={hasReportedPolice}
              onChange={this.handleChange("hasReportedPolice")}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes, has been reported to police"
              />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          {hasReportedPolice && (
            <>
              <FormLabel component="legend">Police reference report</FormLabel>
              <TextField
                label="Police report reference"
                value={policeReportReference}
                onChange={this.handleChange("policeReportReference")}
              />
            </>
          )}
        </div>
      </section>
    );

    const lostMoneyContent = (
      <section>
        <>
          <Paper elevation={1}>
            <h3 className="noRecordMessage-heading">
              Finanacial loss - Deceived into making or diverting a payment
            </h3>
            {this.state.moneyFromToData &&
              this.state.moneyFromToData.length > 0 && (
                <ul className="panel-container">
                  <li className="panel-header">
                    <span>Date</span>
                    <span>Bank</span>
                    <span>BSB</span>
                    <span>Account</span>
                    <span>Amount</span>
                  </li>
                  {this.state.moneyFromToData.map((moneyFromTo, i) => (
                    <li key={i} className="panel-body">
                      <a
                        href="#"
                        className="panel-link"
                        onClick={this.goToRecord(moneyFromTo.id)}
                      >
                        <span>{moneyFromTo.transactionDate}</span>
                        <span>{moneyFromTo.financialInstitution}</span>
                        <span>{moneyFromTo.bsb}</span>
                        <span>{moneyFromTo.accountNumber}</span>
                        <span>{moneyFromTo.amount}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            {this.state.moneyFromToData &&
              this.state.moneyFromToData.length === 0 && (
                <p>No payment transactions on record</p>
              )}
            {this.state.moneyFromToData &&
              this.state.moneyFromToData.length < 10 && (
                <div className="variantButton-container">
                  <Button
                    variant="outlined"
                    onClick={this.openPaymentTransaction}
                    color="primary"
                  >
                    Add a payment transaction
                  </Button>
                </div>
              )}
            {this.state.moneyFromToData &&
              this.state.moneyFromToData.length > 0 && (
                <Paper>{otherMoneyFromToDetail}</Paper>
              )}
          </Paper>
        </>
      </section>
    );

    const lostInfoContent = (
      <section>
        <Paper elevation={1}>
          <h3 className="no-gutterTop no-gutterBottom">
            Sensitive data - Business has shared information
          </h3>

          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              className="leftAligned-question no-gutterBottom"
            >
              Information type
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasLostIp}
                    onChange={this.handleCheck("hasLostIp")}
                    value={hasLostIp}
                  />
                }
                label="Intellectual property"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasLostPi}
                    onChange={this.handleCheck("hasLostPi")}
                    value={hasLostPi}
                  />
                }
                label="Personal information"
              />
            </FormGroup>
          </FormControl>
        </Paper>
      </section>
    );

    const dialogPaymentTransaction = (
      <Dialog
        scroll={"body"}
        open={this.state.showPaymentTransaction}
        onClose={this.handleClose}
        aria-labelledby="add-payment-transaction"
        maxWidth={isMobile ? false : "md"}
        fullScreen={isMobile}
        className="form-control"
      >
        {this.state.curRecord.id && (
          <DialogActions>
            <Button onClick={this.handleDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        )}
        <DialogContent>
          <MoneyFromTo
            ref={this.moneyFromToRef}
            data={this.state.curRecord}
            uniqueBankFrom={uniqueBankFrom}
            uniqueBankTo={this.state.uniqueBankTo}
            isMobile={isMobile}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );

    const content = (
      <>
        <article>
          <>
            <section className="form-control">
              <FormControl component="fieldset">
                <h2>Tell us what happened</h2>
              </FormControl>

              {lostMoneyContent}

              {lostInfoContent}

              <div className="button-group auto">
                <button className="primary-btn" onClick={this.goToNext}>Next</button>
              </div>
            </section>
          </>
        </article>
      </>
    );

    return (
      <div className="bubble-contaner customStepper whatHappened-container form-control">
        <Back link="intro" label="Back to Intro" />

        <Stepper activeStep={activeStep} orientation={orientation}>
          {steps.map((label, index) => {
            let labelProps = {}
            labelProps.error = this.checkHasError(index)
            return (<Step key={index}>
              <StepLabel className="stepLabel" {...labelProps}>
                <a href="#" onClick={this.setupProgressLink(label)}>
                  {label}
                </a>
              </StepLabel>
              {isMobile && <StepContent>{content}</StepContent>}
            </Step>
          )})}
        </Stepper>

        {!isMobile && content}

        {showPaymentTransaction && dialogPaymentTransaction}
      </div>
    );
  }
}

const ConnectedMainUpdate = props => (
  <MainConsumer>
  {({
    aboutYouData,
    businessDetailData,
    whatHappenedData,
    suspectsFormData,
    howDidItHappenData,
    updateData,
    updateHasError,
    hasError
  }) => (
    <WhatHappened
      {...props}
      aboutYouData={aboutYouData}
      businessDetailData={businessDetailData}
      whatHappenedData={whatHappenedData}
      suspectsFormData={suspectsFormData}
      howDidItHappenData={howDidItHappenData}
      hasError={hasError}
      updateData={updateData}
      updateHasError={updateHasError}
    />
  )}
  </MainConsumer>
);
export default ConnectedMainUpdate;
