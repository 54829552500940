import React from 'react'

import './menuBurgerItem.scss'

class MenuBurgerItem extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      hover:false,
    }
  }

  handleHover = () => {
    this.setState((prevState, props) => ({
      hover: !prevState.hover
    }))
  }

  render() {
    const styles={
      menuItem:{
        color: this.state.hover? 'whitesmoke':'white',
      }
    }

    const nextPrevClass = this.props.hasChild ? "next" : this.props.isParentItem ? "prev" : ""

    return(
      <li className={ "menuBurgerItem-container " + nextPrevClass } onClick={this.props.isParentItem === true ? this.props.goBack : null}>

        <div
          className={ "menuBurgerItem-item " + nextPrevClass }
          style={styles.menuItem}
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleHover}
          onClick={this.props.isParentItem === true ? this.props.goBack : this.props.goToPage}
          >
          {this.props.children}
        </div>

        {this.props.hasChild &&
          <a className={ "menuBurgerItem-button " + nextPrevClass }
            onClick={this.props.openSubMenu(this.props.label)}
            name="link to sub menu" role="button"
            aria-atomic="true" tabIndex="0">
          </a>
        }
    </li>
    )
  }
}
export default MenuBurgerItem
