import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "./../../components/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AutoComplete from "./../../components/AutoComplete";
import RefDataSelector from "./../../components/RefDataSelector";

import { countries, australianState } from "../../services/api/static";

import {
  isBlank,
  isValidIP,
  isValidEmail,
  errorCheck,
  isValidWebsite
} from "./../../services/helper";

import "./suspectReport.scss";

const allRules = [
  { name: "ip", isMan: false, rule: isValidIP, errMsg: "Provide valid ip" },
  {
    name: "email",
    isMan: false,
    rule: isValidEmail,
    errMsg: "Provide valid email"
  },
  { name: "country", isMan: false, rule: null, errMsg: "Provide answer" },
  { name: "stateData", isMan: false, rule: null, errMsg: "Provide answer" },
  { name: "firstName", isMan: false, rule: null, errMsg: "Provide answer" },
  { name: "lastName", isMan: false, rule: null, errMsg: "Provide answer" },
  { name: "otherName", isMan: false, rule: null, errMsg: "Provide answer" },
  { name: "businessName", isMan: false, rule: null, errMsg: "Provide answer" },
  { name: "suspectWebsite", isMan: false, rule: isValidWebsite, errMsg: "Provide answer" },

];


class SuspectReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    if (this.props.data !== null) {
      this.setState({
        ...this.props.data
      });
    }
  };

  getData = () => {
    let suspectDetailData = {};
    let id = this.props.data ? this.props.data.id : null;

    const {
      country,
      stateData,
      firstName,
      lastName,
      otherName,
      businessName,
      email,
      suspectWebsite,
      ip
    } = this.state;
    suspectDetailData = {
      country,
      stateData,
      firstName,
      lastName,
      otherName,
      businessName,
      email,
      suspectWebsite,
      ip,
      id
    };

    return suspectDetailData;
  };

  handleBlur = name => e => {
    let errObj = errorCheck(name, e.target.value, allRules);
    let hasError = errObj && errObj.hasError;
    let errMsg = errObj && errObj.errMsg;

    this.setState({
      [name + "Error"]: hasError,
      [name + "ErrMsg"]: hasError ? errMsg : ""
    });
  };

  handleChange = name => e => {
    // reset error
    this.setState({
      [name + "Error"]: false,
      [name + "ErrMsg"]: ""
    });

    let value = e.target.value;

    this.setState({
      [name]: value
    });
  };

  handleRefSelectorChange = name => selected => {
    this.setState({
      [name]: selected
    });
  };

  render() {
    const {
      country,
      stateData,
      firstName,
      lastName,
      otherName,
      businessName,
      email,
      suspectWebsite,
      ip,
      hasError,
      errMsg,
      ipError,
      ipErrMsg
    } = this.state;

    return (
      <div className="suspectReport-container half-area">
        <h3>Suspect</h3>
        <RefDataSelector
          data={countries}
          id="country"
          name="country"
          value={country}
          onChange={this.handleRefSelectorChange("country")}
          label=<span>Suspect lives in (country)<span className="slight-bold"> [Optional]</span></span>
          autoFocus={true}
        />
        {country && country.value === "Australia" && (
          <RefDataSelector
            data={australianState}
            id="stateData"
            name="stateData"
            value={stateData}
            onChange={this.handleRefSelectorChange("stateData")}
            label=<span>Suspect lives in (state)<span className="slight-bold"> [Optional]</span></span>
          />
        )}

        <TextField
          label=<span>Suspect's first name<span className="slight-bold"> [Optional]</span></span>
          value={firstName}
          onChange={this.handleChange("firstName")}
        />
        <TextField
          label=<span>Suspect's last name<span className="slight-bold"> [Optional]</span></span>
          value={lastName}
          onChange={this.handleChange("lastName")}
        />
        <TextField
          label=<span>Does the suspect go by any other name<span className="slight-bold"> [Optional]</span></span>
          value={otherName}
          onChange={this.handleChange("otherName")}
        />
        <TextField
          label=<span>Suspect's business name<span className="slight-bold"> [Optional]</span></span>
          value={businessName}
          onChange={this.handleChange("businessName")}
        />
        <TextField
          label=<span>Suspect's email address<span className="slight-bold"> [Optional]</span></span>
          value={email}
          error={this.state.emailError}
          helperText={this.state.emailErrMsg}
          onChange={this.handleChange("email")}
          onBlur={this.handleBlur("email")}
        />

        <TextField
          label=<span>Suspect's website<span className="slight-bold"> [Optional]</span></span>
          error={this.state.suspectWebsiteError}
          helperText={this.state.suspectWebsiteErrMsg}
          value={this.state.suspectWebsite}
          onChange={this.handleChange("suspectWebsite")}
          onBlur={this.handleBlur("suspectWebsite")}
        />

        <TextField
          label=<span>Suspect's IP address<span className="slight-bold"> [Optional]</span></span>
          error={ipError}
          helperText={ipErrMsg}
          value={this.state.ip}
          onChange={this.handleChange("ip")}
          onBlur={this.handleBlur("ip")}
        />
        <legend className="ip-summary">
          An IP address is a unique address that identifies a device on the
          Internet or a local network. It allows communication and connectivity
          between internet-connected devices and systems via the 'Internet
          Protocol'.
        </legend>
      </div>
    );
  }
}
export default SuspectReport;
