import React from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Back from "./../../components/BackButton";
import EmailDetail from "../../components/EmailDetail";

import { MainConsumer } from "./../../providers/MainProvider";

import { removeWhiteSpace, isBlank, errorCheck } from "./../../services/helper";
import { steps } from "../../services/api/static";

import "./howDidItHappen.scss";

class HowDidItHappen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeStep: 0,
      isMobile: false,
      orientation: "horizontal",
      hasEmailDetail: null,
      emailDetailData: [],
      showEmailDetail: false,
      hasImitateEmail: null,
      curRecord: {}
    };
    this.emailDetailRef = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resize);
    this.resize();
    this.indexCurrentPage();
    if (!isBlank(this.props.howDidItHappenData)) {
      this.setState({
        emailDetailData:this.props.howDidItHappenData
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.howDidItHappenData !== state.emailDetailData) {
      return {
        howDidItHappenData: props.howDidItHappenData
      };
    }
    return null;
  }

  indexCurrentPage = () => {
    let curPage = this.props.location.pathname.slice(1);
    let activeStep;

    steps.map((step, i) => {
      if (curPage === removeWhiteSpace(step.toLowerCase())) {
        activeStep = i;
      }
    });

    this.setState({
      activeStep
    });
  };

  resize = () => {
    let isMobile = window.innerWidth <= 992;
    let orientation = isMobile ? "vertical" : "horizontal";
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile, orientation });
    }
  };

  handleChange = stateValue => e => {
    this.setState({
      [stateValue]: e.target.value
    });
  };

  addEmailDetail = () => {
    let showEmailDetail = true;
    this.setState({ showEmailDetail });
  };

  handleClose = () => {
    let showEmailDetail = false;
    let curRecord = [];
    this.setState({ showEmailDetail, curRecord });
  };

  handleSave = () => {
    // could be new or edit
    let emailDetailData = this.state.emailDetailData;

    let newEmailDetailObj = this.emailDetailRef.current.getData();
    if (newEmailDetailObj.id) {
      emailDetailData = emailDetailData.map(emailDetail =>
        emailDetail.id === newEmailDetailObj.id
          ? (emailDetail = newEmailDetailObj)
          : emailDetail
      );
    } else {
      let id = emailDetailData.length + 1;
      newEmailDetailObj.id = id;
      emailDetailData.push(newEmailDetailObj);
    }

    this.setState({ emailDetailData });

    this.handleClose();
  };

  handleDelete = () => {
    let emailDetailData = this.state.emailDetailData;
    let curRecord = this.state.curRecord;
    emailDetailData = emailDetailData.filter(
      emailDetail => emailDetail.id !== curRecord.id
    );

    this.setState({ emailDetailData });
    this.handleClose();
  };

  saveForm = () => {
    let hasError = this.state.hasError;
    let data = this.state.emailDetailData;

    hasError = false

    this.props.updateHasError(this.state.activeStep, hasError)


    this.setState({
      hasError
    });

    this.props.updateData("howDidItHappenData",data)
  };

  setupProgressLink = page => e => {
    e.preventDefault();
    this.goToPage(page);
  };

  goToPage = page => {
    this.saveForm();
    let link = "/" + removeWhiteSpace(page.toLowerCase());
    this.props.history.push(link);
  };

  goToNext = () => {
    let stepLink = [];
    steps.map(step => stepLink.push(removeWhiteSpace(step.toLowerCase())));

    let curPageUrl = this.props.location.pathname;
    curPageUrl = curPageUrl.slice(1);

    let curPos = stepLink.indexOf(curPageUrl);

    let link = stepLink[curPos + 1];

    this.goToPage(link);
  };

  checkHasError = (index) => {
    let hasError = false
    if(this.props.hasError[index]) {
      hasError = true
    }
    return hasError
  }

  goToRecord = id => e => {
    e.preventDefault();
    let curRecord = this.state.emailDetailData.filter(
      emailDetail => emailDetail.id === id
    );
    this.setState({ curRecord: curRecord[0] });
    this.addEmailDetail();
  };

  render() {
    let {
      activeStep,
      isMobile,
      orientation,
      hasEmailDetail,
      showEmailDetail,
      hasImitateEmail
    } = this.state;

    const mandatoryStatement = (
      <p className="slight-bold mandatory-text">
        All fields are mandatory unless stated otherwise
      </p>
    );

    const dialogEmailDetail = (
      <Dialog
        scroll={"body"}
        open={this.state.showEmailDetail}
        onClose={this.handleClose}
        aria-labelledby="add-payment-transaction"
        maxWidth={isMobile ? false : "md"}
        fullScreen={isMobile}
        className="form-control"
      >
        {this.state.curRecord.id && (
          <DialogActions>
            <Button onClick={this.handleDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        )}
        <DialogContent>
          <EmailDetail ref={this.emailDetailRef} data={this.state.curRecord} />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );

    const content = (
      <>
        <article className="howDidItHappen-container">
          <>
            <section className="form-control">

              <FormControl component="fieldset">
                <h2>Tell us how did it happen</h2>
                {this.state.emailDetailData &&
                  this.state.emailDetailData.length > 0 && (
                    <ul className="panel-container">
                      <li className="panel-header">
                        <span>Date</span>
                        <span>Sender email</span>
                        <span>Subjec</span>
                      </li>
                      {this.state.emailDetailData.map((emailDetail, i) => (
                        <li key={i} className="panel-body">
                          <a
                            href="#"
                            className="panel-link"
                            onClick={this.goToRecord(emailDetail.id)}
                          >
                            <span>{emailDetail.dateReceived}</span>
                            <span>{emailDetail.senderEmailAddress}</span>
                            <span>{emailDetail.subjectLine}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                {this.state.emailDetailData &&
                  this.state.emailDetailData.length === 0 && (
                    <p>No scam email details on record</p>
                  )}
                {this.state.emailDetailData &&
                  this.state.emailDetailData.length < 10 && (
                    <div className="variantButton-container">
                      <Button
                        variant="outlined"
                        onClick={this.addEmailDetail}
                        color="primary"
                      >
                        Add a scam email
                      </Button>
                    </div>
                  )}

                <div className="button-group auto">
                  <button className="primary-btn" onClick={this.goToNext}>
                    Next
                  </button>
                </div>
              </FormControl>
            </section>
          </>
        </article>
      </>
    );

    return (
      <div className="bubble-contaner customStepper form-control">
        <Back link="intro" label="Back to Intro" />

        <Stepper activeStep={activeStep} orientation={orientation}>
          {steps.map((label, index) => {
            let labelProps = {}
            labelProps.error = this.checkHasError(index)
            return (<Step key={index}>
              <StepLabel className="stepLabel" {...labelProps}>
                <a href="#" onClick={this.setupProgressLink(label)}>
                  {label}
                </a>
              </StepLabel>
              {isMobile && <StepContent>{content}</StepContent>}
            </Step>
          )})}
        </Stepper>

        {!isMobile && content}

        {showEmailDetail && dialogEmailDetail}
      </div>
    );
  }
}

const ConnectedMainUpdate = props => (
  <MainConsumer>
  {({
    aboutYouData,
    businessDetailData,
    whatHappenedData,
    suspectsFormData,
    howDidItHappenData,
    updateData,
    updateHasError,
    hasError
  }) => (
    <HowDidItHappen
      {...props}
      aboutYouData={aboutYouData}
      businessDetailData={businessDetailData}
      whatHappenedData={whatHappenedData}
      suspectsFormData={suspectsFormData}
      howDidItHappenData={howDidItHappenData}
      hasError={hasError}
      updateData={updateData}
      updateHasError={updateHasError}
    />
  )}
  </MainConsumer>
);
export default ConnectedMainUpdate;
