import React from "react";
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import TextField from "./../../components/TextField";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import RefDataSelector from "./../../components/RefDataSelector";
import Address from "./../../components/Address";
import Back from "./../../components/BackButton";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";

import { MainConsumer } from "./../../providers/MainProvider";

import * as api from "../../services/api";

import {
  removeWhiteSpace,
  errorCheck,
  isABN,
  isBlank,
  isValidWebsite
} from "./../../services/helper";
import { steps, companySizeData } from "../../services/api/static";

import "./businessdetail.scss";

const allFields = [
  {
    name: "abn",
    isMan: true,
    rule: isABN,
    errMsg: "Provide valid abn"
  },
  {
    name: "companySize",
    isMan: false,
    rule: null,
    errMsg: "Provide company size"
  },
  {
    name: "businessAddress",
    isMan: true,
    rule: null,
    errMsg: "Provide business address"
  },
  {
    name: "businessName",
    isMan: false,
    rule: null,
    errMsg: ""
  },
  {
    name: "businessWebsite",
    isMan: false,
    rule: isValidWebsite,
    errMsg: "Provide business website"
  }
];

class BusinessDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeStep: 0,
      isMobile: false,
      orientation: "horizontal",
      businessName: "",
      businessAddress: ""
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resize);
    this.resize();
    this.indexCurrentPage();
    if (!isBlank(this.props.businessDetailData)) {
      this.setState({
        ...this.props.businessDetailData
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.businessDetailData !== state.businessDetailData) {
      return {
        businessDetailData: props.businessDetailData
      };
    }
    return null;
  }

  indexCurrentPage = () => {
    let curPage = this.props.location.pathname.slice(1);
    let activeStep;

    steps.map((step, i) => {
      if (curPage === removeWhiteSpace(step.toLowerCase())) {
        activeStep = i;
      }
    });

    this.setState({
      activeStep
    });
  };

  resize = () => {
    let isMobile = window.innerWidth <= 992;
    let orientation = isMobile ? "vertical" : "horizontal";
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile, orientation });
    }
  };

  handleBlur = name => e => {
    let errObj = errorCheck(name, e.target.value, allFields);
    let hasError = errObj && errObj.hasError;
    let errMsg = errObj && errObj.errMsg;

    this.setState({
      [name + "Error"]: hasError,
      [name + "ErrMsg"]: hasError ? errMsg : ""
    });
  };

  handleChange = name => e => {
    // reset error
    this.setState({
      [name + "Error"]: false,
      [name + "ErrMsg"]: ""
    });

    let value = e.target.value;

    this.setState({
      [name]: value
    });

    if (name === "abn") {
      this.handleGetAbn(e);
    }
  };

  handleGetAbn = async e => {
    let abn = e.target.value;
    let abnNoSpace = abn;
    if (abnNoSpace.length > 10) {
      let businessNameData = await api.abnGet(abnNoSpace);
      if (businessNameData) {
        let businessName =
          businessNameData.BusinessName &&
          businessNameData.BusinessName.legnth > 0
            ? businessNameData.BusinessName[0]
            : businessNameData.EntityName;
        this.setState({ businessName });
      }
    }
  };

  handleRefSelectorChange = name => {
    return selected => {
      // reset error
      this.setState({
        [name + "Error"]: false,
        [name + "ErrMsg"]: ""
      });

      let value = selected;

      this.setState({
        [name]: selected
      });
    };
  };

  validateForm = () => {
    let hasError = false;
    let errObjArray = [];
    allFields.map(field => {
      let stateValue = this.state[field.name];
      let errorCheckObj = errorCheck(field.name, stateValue, allFields);

      if (errorCheckObj.hasError) {
        hasError = true;
      }

      this.setState({
        [field.name + "Error"]: hasError,
        [field.name + "ErrMsg"]: hasError ? field.errMsg : ""
      });
    });
    return hasError;
  };

  saveForm = () => {
    let hasError = this.state.hasError;
    let data = {};

    allFields.map(
      field =>
        (data[field.name] = !isBlank(this.state[field.name])
          ? this.state[field.name]
          : null)
    );

    hasError = this.validateForm();

    this.props.updateHasError(this.state.activeStep, hasError);

    this.setState({
      businessDetailData: data,
      hasError
    });

    this.props.updateData("businessDetailData", data);
  };

  setupProgressLink = page => e => {
    e.preventDefault();
    this.goToPage(page);
  };

  goToPage = page => {
    this.saveForm();
    let link = "/" + removeWhiteSpace(page.toLowerCase());
    this.props.history.push(link);
  };

  goToNext = () => {
    let stepLink = [];
    steps.map(step => stepLink.push(removeWhiteSpace(step.toLowerCase())));

    let curPageUrl = this.props.location.pathname;
    curPageUrl = curPageUrl.slice(1);

    let curPos = stepLink.indexOf(curPageUrl);

    let link = stepLink[curPos + 1];

    this.goToPage(link);
  };

  checkHasError = index => {
    let hasError = false;
    if (this.props.hasError[index]) {
      hasError = true;
    }
    return hasError;
  };

  updateAddress = value => {
    let name = "businessAddress";
    let errObj = errorCheck(name, value, allFields);
    let hasError = errObj && errObj.hasError;
    let errMsg = errObj && errObj.errMsg;
    this.setState({
      [name]: value,
      [name + "Error"]: hasError,
      [name + "ErrMsg"]: hasError ? errMsg : ""
    });
  };

  render() {
    let {
      activeStep,
      isMobile,
      orientation,
      companySize,
      businessAddress
    } = this.state;

    const mandatoryStatement = (
      <p className="slight-bold mandatory-text">
        All fields are mandatory unless stated otherwise
      </p>
    );

    const content = (
      <>
        <article>
          <>
            <section>
              {mandatoryStatement}
              <FormControl component="fieldset">
                <h2>Business or Organisation details</h2>
                <div className="half-area">
                  <div className="abnGroup-container">
                    <TextField
                      label="ABN"
                      id="abn"
                      type="number"
                      error={this.state.abnError}
                      helperText={this.state.abnErrMsg}
                      onBlur={this.handleBlur("abn")}
                      value={this.state.abn}
                      onChange={this.handleChange("abn")}
                    />
                    <LoadingSpinner loading={this.state.loading}>
                      {this.state.businessName && (
                        <div className="businessName-container">
                          <span>Business name: {this.state.businessName}</span>
                        </div>
                      )}
                    </LoadingSpinner>
                  </div>
                  <RefDataSelector
                    label="Company Size"
                    id="companySize"
                    name="companySize"
                    onChange={this.handleRefSelectorChange("companySize")}
                    data={companySizeData}
                    value={this.state.companySize}
                  />

                  <Address
                    label="Business address"
                    id="businessAddress"
                    name="businessAddress"
                    isMandatory={true}
                    localOnly={true}
                    error={this.state.businessAddressError}
                    helperText={this.state.businessAddressErrMsg}
                    isMobile={isMobile}
                    updateAddress={this.updateAddress}
                    value={this.state.businessAddress}
                  />
                  <TextField
                    type="url"
                    label=<span>
                      Business website address
                      <span className="slight-bold"> [Optional]</span>
                    </span>
                    id="businessWebsite"
                    value={this.state.businessWebsite}
                    onBlur={this.handleBlur("businessWebsite")}
                    onChange={this.handleChange("businessWebsite")}
                    helperText={this.state.businessWebsiteErrMsg}
                    error={this.state.businessWebsiteError}
                  />
                </div>
                <div className="button-group auto">
                  <button className="primary-btn" onClick={this.goToNext}>
                    Next
                  </button>
                </div>
              </FormControl>
            </section>
          </>
        </article>
      </>
    );

    return (
      <div className="businessDetail-container customStepper form-control">
        <Back link="intro" label="Back to Intro" />

        <Stepper activeStep={activeStep} orientation={orientation}>
          {steps.map((label, index) => {
            let labelProps = {};
            labelProps.error = this.checkHasError(index);
            return (
              <Step key={index}>
                <StepLabel className="stepLabel" {...labelProps}>
                  <a href="#" onClick={this.setupProgressLink(label)}>
                    {label}
                  </a>
                </StepLabel>
                {isMobile && <StepContent>{content}</StepContent>}
              </Step>
            );
          })}
        </Stepper>

        {!isMobile && content}
      </div>
    );
  }
}

const ConnectedMainUpdate = props => (
  <MainConsumer>
    {({
      aboutYouData,
      businessDetailData,
      whatHappenedData,
      suspectsFormData,
      howDidItHappenData,
      updateData,
      updateHasError,
      hasError
    }) => (
      <BusinessDetail
        {...props}
        aboutYouData={aboutYouData}
        businessDetailData={businessDetailData}
        whatHappenedData={whatHappenedData}
        suspectsFormData={suspectsFormData}
        howDidItHappenData={howDidItHappenData}
        hasError={hasError}
        updateData={updateData}
        updateHasError={updateHasError}
      />
    )}
  </MainConsumer>
);
export default ConnectedMainUpdate;
