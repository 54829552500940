import React from 'react'
import { Link } from 'react-router-dom';

import LoadingSpinner from './../../components/LoadingSpinner'
import { goToPage } from './../../services/helper'
import Address from './../../components/Address'
import Styleguide from './../../pages/Styleguide'
import Button from '@material-ui/core/Button';

import './intro.scss'

let content = <><p>Your report is important and directly contributes to the ACSC developing a broader understanding of current cyber incidents affecting Australians. This enables us to respond to malicious online activity in addition to providing accurate mitigation advice to the Australian community.

To ensure your report is sent to the right place please provide accurate and complete responses to the following questions.

This is a reporting and referral tool only - it does not have any investigative or recovery powers. You will be notified of any further action in relation to your specific report - this will be undertaken by relevant law enforcement agencies. Should you need to contact the ACSC please use the contact address: help@cyber.gov.au </p><p>Quick access to <a href="#/styleguide">Styleguide</a></p></>

export const Intro = (props) => {
  return (
    <div className="intro-contaner">
      {content}
      <button className="primary-btn" onClick={goToPage("aboutyou", props)}>Start</button>
    </div>
  )
}
export default Intro
