import React from "react";
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "./../../components/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Address from "./../../components/Address";
import Back from "./../../components/BackButton";
import RefDataSelector from "./../../components/RefDataSelector";

import { MainConsumer } from "./../../providers/MainProvider";

import {
  removeWhiteSpace,
  errorCheck,
  isValidPhone,
  isValidEmail,
  isBlank
} from "./../../services/helper";
import { steps, gender } from "../../services/api/static";

import "./aboutyou.scss";

const allFields = [
  { name: "notPointOfContact", isMan: true, rule: null, errMsg: "Provide answer" },
  {
    name: "nonContactfirstName",
    isMan: false,
    rule: null,
    errMsg: "Provide first name"
  },
  {
    name: "nonContactlastName",
    isMan: false,
    rule: null,
    errMsg: "Provide last name"
  },
  {
    name: "nonContactBusinessRole",
    isMan: false,
    rule: null,
    errMsg: "Provide business role"
  },
  {
    name: "nonContactPhoneNumber",
    isMan: false,
    rule: isValidPhone,
    errMsg: "Provide valid contact number"
  },
  {
    name: "nonContactAltPhoneNumber",
    isMan: false,
    rule: isValidPhone,
    errMsg: "Provide valid contact number"
  },
  { name: "firstName", isMan: true, rule: null, errMsg: "Provide first name" },
  { name: "lastName", isMan: true, rule: null, errMsg: "Provide last name" },
  { name: "gender", isMan: true, rule: null, errMsg: "Provide gender" },
  { name: "dob", isMan: true, rule: null, errMsg: "Provide date of birth" },
  {
    name: "address",
    isMan: true,
    rule: null,
    errMsg: "Provide physical address"
  },
  {
    name: "phoneNumber",
    isMan: true,
    rule: isValidPhone,
    errMsg: "Provide valid contact number"
  },
  {
    name: "altPhoneNumber",
    isMan: false,
    rule: isValidPhone,
    errMsg: "Provide valid contact number"
  },
  {
    name: "email",
    isMan: true,
    rule: isValidEmail,
    errMsg: "Provide valid email"
  },
  {
    name: "businessRole",
    isMan: false,
    rule: null,
    errMsg: "Provide business role"
  },
  {
    name: "isTorresIslander",
    isMan: true,
    rule: null,
    errMsg: "Provide torres islander status"
  }
];

class AboutYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      isMobile: false,
      orientation: "horizontal",
      notPointOfContact: "",
      gender: null,
      isTorresIslander: null,
      dob: ""
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resize);
    this.resize();
    this.indexCurrentPage();
    if (!isBlank(this.props.aboutYouData)) {
      this.setState({
        ...this.props.aboutYouData
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.aboutYouData !== state.aboutYouData) {
      return {
        aboutYouData: props.aboutYouData
      };
    }
    return null;
  }

  indexCurrentPage = () => {
    let curPage = this.props.location.pathname.slice(1);
    let activeStep = this.getPageIndex(curPage);

    this.setState({
      activeStep
    });
  };

  resize = () => {
    let isMobile = window.innerWidth <= 992;
    let orientation = isMobile ? "vertical" : "horizontal";
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile, orientation });
    }
  };

  handleBlur = name => e => {
    let errObj = errorCheck(name, e.target.value, allFields);
    let hasError = errObj && errObj.hasError;
    let errMsg = errObj && errObj.errMsg;

    this.setState({
      [name + "Error"]: hasError,
      [name + "ErrMsg"]: hasError ? errMsg : ""
    });
  };

  handleChange = name => e => {
    // reset error
    this.setState({
      [name + "Error"]: false,
      [name + "ErrMsg"]: ""
    });

    let value = e.target.value;

    this.setState({
      [name]: value
    });
  };

  handleCheck = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleRefSelectorChange = name => selected => {
    // reset error
    this.setState({
      [name + "Error"]: false,
      [name + "ErrMsg"]: ""
    });

    this.setState({
      [name]: selected
    });
  };

  updateAddress = value => {
    let name = "address";
    let errObj = errorCheck(name, value, allFields);
    let hasError = errObj && errObj.hasError;
    let errMsg = errObj && errObj.errMsg;
    this.setState({
      [name]: value,
      [name + "Error"]: hasError,
      [name + "ErrMsg"]: hasError ? errMsg : ""
    });
  };

  validateForm = () => {
    let hasError = false;
    allFields.map(field => {
      let stateValue = this.state[field.name];
      let errorCheckObj = errorCheck(field.name, stateValue, allFields);

      if (errorCheckObj.hasError) {
        hasError = true;
      }

      this.setState({
        [field.name + "Error"]: hasError,
        [field.name + "ErrMsg"]: hasError ? field.errMsg : ""
      });
    });
    return hasError;
  };

  saveForm = () => {
    let data = {};
    let hasError = false;

    allFields.map(
      field =>
        (data[field.name] = !isBlank(this.state[field.name])
          ? this.state[field.name]
          : null)
    );

    hasError = this.validateForm();

    this.props.updateHasError(this.state.activeStep, hasError);

    this.setState({
      allYouData: data,
      hasError
    });

    this.props.updateData("aboutYouData", data);
  };

  setupProgressLink = page => e => {
    e.preventDefault();
    this.goToPage(page);
  };

  goToPage = page => {
    this.saveForm();
    let link = "/" + removeWhiteSpace(page.toLowerCase());
    this.props.history.push(link);
  };

  getPageIndex = page => {
    let stepLink = [];
    steps.map(step => stepLink.push(removeWhiteSpace(step.toLowerCase())));

    return stepLink.indexOf(page);
  };

  goToNext = () => {
    let curPageUrl = this.props.location.pathname;
    let curPage = curPageUrl.slice(1);

    let stepLink = [];
    steps.map(step => stepLink.push(removeWhiteSpace(step.toLowerCase())));

    let curPos = stepLink.indexOf(curPage);

    let link = stepLink[curPos + 1];

    this.goToPage(link);
  };

  checkHasError = index => {
    let hasError = false;
    if (this.props.hasError[index]) {
      hasError = true;
    }
    return hasError;
  };

  render() {
    let { activeStep, isMobile, orientation, notPointOfContact, dob } = this.state;

    const mandatoryStatement = (
      <p className="slight-bold mandatory-text">
        All fields are mandatory unless stated otherwise
      </p>
    );

    const torresIslander = (
      <>
        <RadioGroup
          aria-label="Torres Strait Islander origin"
          id="torresIslander"
          name="torresIslander"
          ref="torresIslander"
          error={this.state.isTorresIslanderError}
          value={this.state.isTorresIslander}
          onChange={this.handleChange("isTorresIslander")}
          onBlur={this.handleBlur("isTorresIslander")}
        >
          <FormLabel component="legend" className="leftAligned-question">
            Aboriginal or Torres Strait Islander origin?
          </FormLabel>
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label="Yes"
            labelPlacement="end"
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label="No"
            labelPlacement="end"
          />
          <FormControlLabel
            value="notAnswer"
            control={<Radio />}
            label="Prefer not to answer"
            labelPlacement="end"
          />
        </RadioGroup>
      </>
    );

    const content = (
      <article>
        <section>
          {mandatoryStatement}
          <FormControl component="fieldset">
            <h2>Point of Contact</h2>

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.notnotPointOfContact}
                  onChange={this.handleCheck("notnotPointOfContact")}
                  value={this.state.notnotPointOfContact}
                />
              }
              label="I am submitting on behalf of the business victim and I am not the contact person"
            />

          </FormControl>
          <div>
            {notPointOfContact && (
              <>
                {notPointOfContact && (
                  <>
                    <h2>Your detail</h2>
                    <div className="half-area">
                      <TextField
                        label=<span>
                          First name
                          <span className="slight-bold"> [Optional]</span>
                        </span>
                        name="nonContactfirstName"
                        error={this.state.nonContactfirstNameError}
                        helperText={this.state.nonContactfirstNameErrMsg}
                        id="nonContactfirstName"
                        onBlur={this.handleBlur("nonContactfirstName")}
                        onChange={this.handleChange("nonContactfirstName")}
                        value={this.state.nonContactfirstName}
                      />
                      <TextField
                        label=<span>
                          Last name
                          <span className="slight-bold"> [Optional]</span>
                        </span>
                        name="nonContactlastName"
                        error={this.state.nonContactlastNameError}
                        helperText={this.state.nonContactlastNameErrMsg}
                        id="nonContactlastName"
                        onBlur={this.handleBlur("nonContactlastName")}
                        onChange={this.handleChange("nonContactlastName")}
                        value={this.state.nonContactlastName}
                      />
                    </div>
                    <div className="fullWidth-input">
                      <TextField
                        label=<span>
                          Business role
                          <span className="slight-bold"> [Optional]</span>
                        </span>
                        name="nonContactBusinessRole"
                        error={this.state.nonContactBusinessRoleError}
                        helperText={this.state.nonContactBusinessRoleErrMsg}
                        id="nonContactBusinessRole"
                        onBlur={this.handleBlur("nonContactBusinessRole")}
                        onChange={this.handleChange("nonContactBusinessRole")}
                        value={this.state.nonContactBusinessRole}
                      />
                    </div>
                    <div className="half-area">
                      <TextField
                        label=<span>
                          Contact number
                          <span className="slight-bold"> [Optional]</span>
                        </span>
                        type="number"
                        name="nonContactPhoneNumber"
                        error={this.state.nonContactPhoneNumberError}
                        helperText={this.state.nonContactPhoneNumberErrMsg}
                        id="nonContactPhoneNumber"
                        onBlur={this.handleBlur("nonContactPhoneNumber")}
                        onChange={this.handleChange("nonContactPhoneNumber")}
                        value={this.state.nonContactPhoneNumber}
                      />
                      <TextField
                        label=<span>
                          Alternative contact number
                          <span className="slight-bold"> [Optional]</span>
                        </span>
                        type="number"
                        name="nonContactAltPhoneNumber"
                        error={this.state.nonContactAltPhoneNumberError}
                        helperText={this.state.nonContactAltPhoneNumberErrMsg}
                        id="nonContactAltPhoneNumber"
                        onBlur={this.handleBlur("nonContactAltPhoneNumber")}
                        onChange={this.handleChange("nonContactAltPhoneNumber")}
                        value={this.state.nonContactAltPhoneNumber}
                      />
                    </div>
                    {torresIslander}
                  </>
                )}
                <h2 className="aboutYou-heading">Contact Person Details</h2>
                {notPointOfContact === "no" && (
                  <FormLabel
                    component="legend"
                    className="leftAligned-question"
                  >
                    Please provide us with a point of contact
                  </FormLabel>
                )}
                <div className="firstLast-container">
                  <TextField
                    label="First name"
                    name="firstName"
                    error={this.state.firstNameError}
                    helperText={this.state.firstNameErrMsg}
                    id="firstName"
                    onBlur={this.handleBlur("firstName")}
                    onChange={this.handleChange("firstName")}
                    value={this.state.firstName}
                  />
                  <TextField
                    label="Last name"
                    id="lastName"
                    name="lastName"
                    error={this.state.lastNameError}
                    helperText={this.state.lastNameErrMsg}
                    onBlur={this.handleBlur("lastName")}
                    onChange={this.handleChange("lastName")}
                    value={this.state.lastName}
                  />
                </div>
                <div className="half-area">
                  <RefDataSelector
                    label="Gender"
                    name="gender"
                    id="gender"
                    error={this.state.genderError}
                    helperText={this.state.genderErrMsg}
                    onChange={this.handleRefSelectorChange("gender")}
                    data={gender}
                    onBlur={this.handleBlur("gender")}
                    value={this.state.gender}
                  />
                  <TextField
                    type="date"
                    id="dob"
                    label="Date of Birth"
                    error={this.state.dobError}
                    helperText={this.state.dobErrMsg}
                    InputLabelProps={{ shrink: true }}
                    onBlur={this.handleBlur("dob")}
                    onChange={this.handleChange("dob")}
                    value={this.state.dob}
                  />
                </div>
                <Address
                  label="Physical address"
                  id="address"
                  name="address"
                  isMandatory={true}
                  error={this.state.addressError}
                  helperText={this.state.addressErrMsg}
                  isMobile={isMobile}
                  updateAddress={this.updateAddress}
                  value={this.state.address}
                />
                <div className="half-area">
                  <TextField
                    label="Contact number"
                    name="phoneNumber"
                    id="phoneNumber"
                    type="number"
                    error={this.state.phoneNumberError}
                    helperText={this.state.phoneNumberErrMsg}
                    onBlur={this.handleBlur("phoneNumber")}
                    onChange={this.handleChange("phoneNumber")}
                    value={this.state.phoneNumber}
                  />
                  <TextField
                    label="Alternative contact number"
                    id="altPhoneNumber"
                    name="altPhoneNumber"
                    type="number"
                    error={this.state.altPhoneNumberError}
                    helperText={this.state.altPhoneNumberErrMsg}
                    onBlur={this.handleBlur("altPhoneNumber")}
                    onChange={this.handleChange("altPhoneNumber")}
                    value={this.state.altPhoneNumber}
                  />
                  <TextField
                    label="Email"
                    id="email"
                    name="email"
                    error={this.state.emailError}
                    helperText={this.state.emailErrMsg}
                    onBlur={this.handleBlur("email")}
                    onChange={this.handleChange("email")}
                    value={this.state.email}
                  />
                  <TextField
                    label=<span>
                      Business role
                      <span className="slight-bold"> [Optional]</span>
                    </span>
                    id="businessRole"
                    name="businessRole"
                    error={this.state.businessRoleError}
                    helperText={this.state.businessRoleErrMsg}
                    onBlur={this.handleBlur("businessRole")}
                    onChange={this.handleChange("businessRole")}
                    value={this.state.businessRole}
                  />
                  {notPointOfContact === "yes" && torresIslander}
                </div>
              </>
            )}
          </div>
          <div className="button-group auto">
            <button className="primary-btn" onClick={this.goToNext}>
              Next
            </button>
          </div>
        </section>
      </article>
    );

    return (
      <div className="aboutYou-contaner customStepper form-control">
        <Back link="intro" label="Back to Intro" />

        <Stepper activeStep={activeStep} orientation={orientation}>
          {steps.map((label, index) => {
            let labelProps = {};
            labelProps.error = this.checkHasError(index);
            return (
              <Step key={index}>
                <StepLabel className="stepLabel" {...labelProps}>
                  <a href="#" onClick={this.setupProgressLink(label)}>
                    {label}
                  </a>
                </StepLabel>
                {isMobile && <StepContent>{content}</StepContent>}
              </Step>
            );
          })}
        </Stepper>

        {!isMobile && content}
      </div>
    );
  }
}

const ConnectedMainUpdate = props => (
  <MainConsumer>
    {({
      aboutYouData,
      businessDetailData,
      whatHappenedData,
      suspectsFormData,
      howDidItHappenData,
      updateData,
      updateHasError,
      hasError
    }) => (
      <AboutYou
        {...props}
        aboutYouData={aboutYouData}
        businessDetailData={businessDetailData}
        whatHappenedData={whatHappenedData}
        suspectsFormData={suspectsFormData}
        howDidItHappenData={howDidItHappenData}
        hasError={hasError}
        updateData={updateData}
        updateHasError={updateHasError}
      />
    )}
  </MainConsumer>
);
export default ConnectedMainUpdate;
