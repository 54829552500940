import React from 'react'

import './menuBurger.scss'

/* MenuBurger.jsx */
class MenuBurger extends React.Component {
  constructor(props){
    super(props);
    this.state={
      open: this.props.open? this.props.open:false,
      color: this.props.color? this.props.color:'white',
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
     if(nextProps.open!==prevState.open){
       return { open: nextProps.open};
    }
    else return null;
  }

  handleClick(){
    this.setState((prevState, props) => ({
      open: !prevState.open
    }))
  }

  render(){
    const styles = {
      container: {
        height: '32px',
        width: '32px',
        cursor: 'pointer',
        padding: '4px',
      },
      line: {
        height: '2px',
        width: '20px',
        background: this.state.color,
        transition: 'all 0.2s ease',
      },
      lineTop: {
        transform: this.state.open ? 'rotate(45deg)':'none',
        transformOrigin: 'top left',
        marginBottom: '5px',
      },
      lineMiddle: {
        opacity: this.state.open ? 0: 1,
        transform: this.state.open ? 'translateX(-16px)':'none',
      },
      lineBottom: {
        transform: this.state.open ? 'translateX(-1px) rotate(-45deg)':'none',
        transformOrigin: 'top left',
        marginTop: '5px',
      },
    }
    return(
      <div style={styles.container}
        onClick={this.props.onClick ? this.props.onClick : this.handleClick}>
        <div style={{...styles.line,...styles.lineTop}}/>
        <div style={{...styles.line,...styles.lineMiddle}}/>
        <div style={{...styles.line,...styles.lineBottom}}/>
      </div>
    )
  }
}
export default MenuBurger
