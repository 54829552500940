import React from "react";
import { HashRouter, Route, Switch, withRouter } from "react-router-dom";

import BusinessDetail from "./../../pages/BusinessDetail";
import Styleguide from "./../../pages/Styleguide";
import Intro from "./../../pages/Intro";
import AboutYou from "./../../pages/AboutYou";
import WhatHappened from "./../../pages/WhatHappened";
import HowDidItHappen from "./../../pages/HowDidItHappen";
import SuspectDetail from "./../../pages/SuspectDetail";
import Submit from "./../../pages/Submit";
import Thankyou from "./../../pages/Thankyou";

import MainProvider from "./../../providers/MainProvider"

import './main.scss'

class Main extends React.Component {

  render() {
    return (
      <div className="body-container">
      <MainProvider>
        <Switch>
          <Route exact path="/" component={Intro} />
          <Route path="/styleguide" component={Styleguide} />
          <Route path="/intro" component={Intro} />
          <Route path="/aboutyou" component={AboutYou} />
          <Route path="/businessdetail" component={BusinessDetail} />
          <Route path="/whathappened" component={WhatHappened} />
          <Route path="/howdidithappen" component={HowDidItHappen} />
          <Route path="/suspectdetail" component={SuspectDetail} />
          <Route path="/submit" component={Submit} />
          <Route path="/thankyou" component={Thankyou} />
        </Switch>
      </MainProvider>
      </div>
    );
  }
}

export default withRouter(Main)
