import React from 'react'
import MenuBurgerItem from './../MenuBurgerItem'

import './menuBurgerWrapper.scss'

class MenuBurgerWrapper extends React.Component {
  constructor(props){
    super(props);
    this.state={
      isShowingSubMenu:false,
      parentLabel:""
    }
  }

// could either be actioning href or opening sub panel
// goBack, goToPage, openSubMenu

  goBack = () => {
    this.setState({
      isShowingSubMenu: false,
      parentLabel:""
    })
  }

  openSubMenu = (parentLabel) => {
    return() => {
      this.setState({
        isShowingSubMenu: true,
        parentLabel
      })
    }
  }

  // go to page will generate url in cyber.gov.au
  goToPage = (menuObj) => {
    return() => {
      const baseURL = "http://cyber.gov.au"
      const parentLink = this.state.parentLabel.toLowerCase()
      const baseParentURL = baseURL + '/' + parentLink
      let goToLink = ""

      goToLink = menuObj.isRoot === true ? baseParentURL : baseParentURL + '/' + menuObj.link

      return window.location.href = goToLink
    }
  }

  render(){
    const topNavMenu = this.props.topNavMenu.map((topNavItem,i) =>
      <MenuBurgerItem
        key={i}
        delay={`${i * 0.1}s`}
        goToPage={this.goToPage(topNavItem)}
        openSubMenu={this.openSubMenu}
        hasChild={topNavItem.hasChild}
        label={topNavItem.label}
      >
      {topNavItem.label}
      </MenuBurgerItem>
    );

    const parentLink = this.state.parentLabel ? this.state.parentLabel.toLowerCase() : ""

    const relevantSubMenu = this.props.subMenu.filter(subMenu => subMenu.except.indexOf(parentLink) === -1);

    const subMenu = relevantSubMenu.map((subMenuItem,i) =>
      <MenuBurgerItem
        key={i}
        delay={`${i * 0.1}s`}
        goToPage={this.goToPage(subMenuItem)}
        hasChild={false}
        goBack={this.goBack}
      >
      {subMenuItem.label}
      </MenuBurgerItem>
    )

    const parentItem =
      <MenuBurgerItem
        goBack={this.goBack}
        isParentItem={true}>{this.state.parentLabel}
      </MenuBurgerItem>

    return (
      <ul className="menuBurgerWrapper-container">
        {!this.state.isShowingSubMenu &&
          topNavMenu
        }
        {this.state.isShowingSubMenu &&
          <>
          {parentItem}
          {subMenu}
          </>
        }
      </ul>
    )
  }
}

export default MenuBurgerWrapper
