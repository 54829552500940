import React from 'react';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import { removeWhiteSpace } from './../../services/helper'

import './autoComplete.scss'


class AutoComplete extends React.Component {
  state = {
    inputValue: '',
    selectedItem: null,
    suggestions:[],
    textvalue:null,
  };

  renderInput = (inputProps) => {
    const { InputProps, classes, ref, ...other } = inputProps;
    const { required, helperText} = this.props;

    return (
      <TextField
        helperText={helperText}
        InputLabelProps={{
          required: false
        }}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
    );
  }

   renderSuggestion = ({ suggestion, index, itemProps, highlightedIndex, selectedItem }) => {
    const isHighlighted = highlightedIndex === index;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: 400,
        }}
      >
        {suggestion}
      </MenuItem>
    );
  }

  onBlur = (e) => {
  }

  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.state;
    if (selectedItem && event.keyCode !== 9) {
      this.setState({
        selectedItem: null,
        inputValue:''
      });
    }
  };

  handleInputChange = e => {
    if(this.props.data) {
      let inputValue = removeWhiteSpace(e.target.value).toLowerCase()
      this.setState({inputValue})
      let suggestions = this.props.data.filter((suggestion) => removeWhiteSpace(suggestion).toLowerCase().indexOf(inputValue) > -1)
      this.setState({
        suggestions
      })
    } else {
      console.log("AutoComplete requires props data")
    }
  };

  onSuggestionSelected = selectedItem => {
    this.setState({
      inputValue: selectedItem,
      selectedItem,
    });
  };

  render() {
    const { classes, label } = this.props;
    const { inputValue, selectedItem, suggestions } = this.state;

    return (
      <div className="AutoComplete-container">

        <Downshift
          inputValue={inputValue}
          onChange={this.onSuggestionSelected}
          selectedItem={selectedItem}
        >
          {({
            getInputProps,
            getItemProps,
            isOpen,
            inputValue,
            selectedItem,
            highlightedIndex,
          }) => (
            <div className={classes.container}>
              {this.renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                onChange: this.handleInputChange,
                onBlur:this.onBlur,
                onKeyDown: this.handleKeyDown,
                }),
                label,
              })}
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {suggestions && suggestions.map((suggestion, index) =>
                    this.renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem,
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>

      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    maxHeight:'150px',
    overflowY:'scroll'
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});


export default withStyles(styles)(AutoComplete);
