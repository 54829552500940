import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "./../../components/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import RefDataSelector from "./../../components/RefDataSelector";
import LoadingSpinner from "./../../components/LoadingSpinner";

import { paymentMethod } from "./../../services/api/static";

import { isBlank, errorCheck, findBSBName, isValidBSB } from "./../../services/helper";

import "./moneyFromTo.scss";

const allFields = [
  {
    name: "accountName",
    isMan: true,
    rule: null,
    errMsg: "Provide account name"
  },
  { name: "bsb", isMan: true, rule: null, errMsg: "Provide bsb number" },
  {
    name: "financialInstitution",
    isMan: false,
    rule: null,
    errMsg: "Provide financial institute"
  },
  {
    name: "accountNumber",
    isMan: true,
    rule: null,
    errMsg: "Provide account number"
  },
  {
    name: "paymentMethodData",
    isMan: true,
    rule: null,
    errMsg: "Provide payment method date"
  },
  {
    name: "payIDType",
    isMan: false,
    rule: null,
    errMsg: "Provide pay ID type"
  },
  { name: "payID", isMan: false, rule: null, errMsg: "Provide pay ID" },
  {
    name: "amountReference",
    isMan: false,
    rule: null,
    errMsg: "Provide amount reference"
  },
  {
    name: "transactionDescription",
    isMan: false,
    rule: null,
    errMsg: "Provide transaction description"
  },
  {
    name: "transactionDate",
    isMan: false,
    rule: null,
    errMsg: "Provide transaction date"
  }
];

// could be new or edit, if there is no Data (aka. undefined) this is a new record
// if this is new, we will set id to null and parent page will handle it

class MoneyFromTo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: true,
      uniqueBankFrom: props.uniqueBankFrom ? props.uniqueBankFrom : [],
      uniqueBankTo: props.uniqueBankTo ? props.uniqueBankTo : [],
      refUniqueBankFrom: [],
      refUniqueBankTo: [],
      bankFromData: "",
      bankToData: "",
      accountName: ""
    };
  }

  componentDidMount = () => {
    if (this.props.data !== null) {
      this.setState({
        ...this.props.data
      });
    }
    this.createRefUniqueBankFrom();
    this.createRefUniqueBankTo();
  };

  createRefUniqueBankFrom = () => {
    let refUniqueBankFrom = [];
    if (this.state.uniqueBankFrom.length > 0) {
      this.state.uniqueBankFrom.map(unique => {
        let label =
          unique.financialInstitution +
          ": " +
          unique.bsb +
          " " +
          unique.accountNumber;
        let value = unique.id.toString();
        let temp = {};
        temp.label = label;
        temp.value = value;
        refUniqueBankFrom.push(temp);
      });
    }
    this.setState({ refUniqueBankFrom });
    return refUniqueBankFrom;
  };
  createRefUniqueBankTo = () => {
    let refUniqueBankTo = [];
    if (this.state.uniqueBankTo.length > 0) {
      this.state.uniqueBankTo.map(unique => {
        let label = "";
        if (!isBlank(unique.payID)) {
          label = unique.payIDType + ": " + unique.payID;
        } else {
          label =
            unique.suspectAccountName +
            ": " +
            unique.suspectBSB +
            " " +
            unique.suspectAccountNumber;
        }

        let value = unique.id.toString();
        let temp = {};
        temp.label = label;
        temp.value = value;
        refUniqueBankTo.push(temp);
      });
    }
    this.setState({ refUniqueBankTo });
    return refUniqueBankTo;
  };

  handleBlur = name => e => {
    let value = e.target.value
    let errObj = errorCheck(name, value, allFields);
    let hasError = errObj && errObj.hasError;
    let errMsg = errObj && errObj.errMsg;

    this.setState({
      [name + "Error"]: hasError,
      [name + "ErrMsg"]: hasError ? errMsg : ""
    });

    if(name === "bsb") {
      let financialInstitution = findBSBName(value)
      this.setState({
        financialInstitution,
        loading: false
      })
    } else {
      this.setState({
        loading: false
      })
    }
  };

  handleChange = name => e => {
    // reset error
    this.setState({
      [name + "Error"]: false,
      [name + "ErrMsg"]: ""
    });

    let value = e.target.value;

    if(name === "bsb" && value.length < 6) {
      let financialInstitution = findBSBName(value)
      this.setState({
        loading: true,
        financialInstitution
      })
    } else {
      this.setState({
        loading: false
      })
    }

    this.setState({
      [name]: value
    });

  };

  handleRefSelectorChange = name => selected => {
    // reset error
    this.setState({
      [name + "Error"]: false,
      [name + "ErrMsg"]: ""
    });

    this.setState({
      [name]: selected
    });
  };

  getData = () => {
    let moneyFromToData = {};
    let bankAccountObj = {};
    let payIdObj = {};

    let id = this.props.data ? this.props.data.id : null;

    const {
      accountName,
      bsb,
      financialInstitution,
      accountNumber,
      paymentMethodData,
      amount,
      amountReference,
      transactionDescription,
      transactionDate,
      suspectAccountName,
      suspectBSB,
      suspectAccountNumber,
      payIDType,
      payID
    } = this.state;
    moneyFromToData = {
      accountName,
      bsb,
      financialInstitution,
      accountNumber,
      paymentMethodData,
      amountReference,
      transactionDescription,
      transactionDate,
      amount,
      suspectAccountName,
      suspectBSB,
      suspectAccountNumber,
      payIDType,
      payID,
      id
    };

    return moneyFromToData;
  };

  render() {
    let {
      accountName,
      bsb,
      financialInstitution,
      accountNumber,
      paymentMethodData,
      amount,
      amountReference,
      transactionDescription,
      transactionDate,
      uniqueBankFrom,
      refUniqueBankFrom,
      bankFromData
    } = this.state;

    const toBankAccountContent = (
      <section className="half-area">
        <TextField
          id="suspectAccountName"
          label="Suspect account name"
          error={this.state.suspectAccountNameError}
          helperText={this.state.suspectAccountNameErrMsg}
          onBlur={this.handleBlur("suspectAccountName")}
          onChange={this.handleChange("suspectAccountName")}
        />
        <TextField
          label="BSB number"
          id="suspectBSB"
          type="number"
          error={this.state.suspctBSBError}
          helperText={this.state.suspctBSBNameErrMsg}
          onBlur={this.handleBlur("suspctBSB")}
          onChange={this.handleChange("suspctBSB")}
        />
        <TextField
          label="Account number"
          id="suspectAccountNumber"
          error={this.state.suspectAccountNumberError}
          helperText={this.state.suspectAccountNumberErrMsg}
          onBlur={this.handleBlur("suspectAccountNumber")}
          onChange={this.handleChange("suspectAccountNumber")}
        />
      </section>
    );

    const toPayIdContent = (
      <section className="half-area">
        <TextField
          label="PayID type"
          id="payID"
          error={this.state.payIDTypeError}
          helperText={this.state.payIDTypeErrMsg}
          onBlur={this.handleBlur("payIDType")}
          onChange={this.handleChange("payIDType")}
          value={this.state.payIDType}
        />
        <TextField
          label="PayID"
          error={this.state.payIDError}
          helperText={this.state.payIDErrMsg}
          onBlur={this.handleBlur("payID")}
          onChange={this.handleChange("payID")}
          id="payID"
          value={this.state.payID}
        />
      </section>
    );

    return (
      <div className="moneyFromTo-container">
        <>
          <h3>Transferred</h3>

          <div className="half-container">
            <h4>From</h4>

            <FormLabel component="legend" className="leftAligned-question">
              Financial institute details
            </FormLabel>
            <>
              <TextField
                id="accountName"
                label="Account name"
                value={this.state.accountName}
                onChange={this.handleChange("accountName")}
                error={this.state.accountNameError}
                helperText={this.state.accountNameErrMsg}
                onBlur={this.handleBlur("accountName")}
              />
              <TextField
                id="bsb"
                label="BSB number"
                type="number"
                onChange={this.handleChange("bsb")}
                error={this.state.bsbError}
                helperText={this.state.bsbErrMsg}
                onBlur={this.handleBlur("bsb")}
                value={this.state.bsb}
              />
              <LoadingSpinner loading={this.state.loading}>
                <TextField
                  id="financialInstitution"
                  label="Name of financial institution"
                  onChange={this.handleChange("financialInstitution")}
                  error={this.state.financialInstitutionError}
                  helperText={this.state.financialInstitutionErrMsg}
                  onBlur={this.handleBlur("financialInstitution")}
                  value={this.state.financialInstitution}
                />
              </LoadingSpinner>
              <TextField
                id="accountNumber"
                label="Account number"
                onChange={this.handleChange("accountNumber")}
                error={this.state.accountNumberError}
                helperText={this.state.accountNumberErrMsg}
                onBlur={this.handleBlur("accountNumber")}
                value={this.state.accountNumber}
              />
            </>
          </div>
          <div className="half-container">
            <h4>To</h4>

            <FormLabel component="legend" className="leftAligned-question">
              Financial institute details
            </FormLabel>
            <RefDataSelector
              label="How did you pay?"
              id="paymentMethod"
              name="paymentMethod"
              data={paymentMethod}
              onChange={this.handleRefSelectorChange("paymentMethodData")}
              value={paymentMethodData}
            />
            {paymentMethodData &&
              paymentMethodData === "bankAccount" &&
              toBankAccountContent}
            {paymentMethodData &&
              paymentMethodData === "payId" &&
              toPayIdContent}

            <TextField
              label="Amount transferred was (AUD)?"
              id="amount"
              type="number"
              error={this.state.amountError}
              helperText={this.state.amountErrMsg}
              onBlur={this.handleBlur("amount")}
              onChange={this.handleChange("amount")}
              value={this.state.amount}
            />
            <TextField
              label="The transaction reference is"
              id="amountReference"
              error={this.state.amountReferenceError}
              helperText={this.state.amountReferenceErrMsg}
              onBlur={this.handleBlur("amountReference")}
              onChange={this.handleChange("amountReference")}
              value={this.state.amountReference}
            />
            <TextField
              label="The transaction description entered was"
              id="transactionDescription"
              error={this.state.transactionDescriptionError}
              helperText={this.state.transactionDescriptionErrMsg}
              onBlur={this.handleBlur("transactionDescription")}
              onChange={this.handleChange("transactionDescription")}
              value={this.state.transactionDescription}
            />
            <TextField
              id="transactionDate"
              label="Transaction date"
              type="date"
              InputLabelProps={{ shrink: true }}
              error={this.state.transactionDateError}
              helperText={this.state.transactionDateErrMsg}
              onBlur={this.handleBlur("transactionDate")}
              onChange={this.handleChange("transactionDate")}
              value={this.state.transactionDate}
            />
          </div>
        </>
      </div>
    );
  }
}
export default MoneyFromTo;
